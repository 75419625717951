<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Update Process Rates ({{ page_data.process_details?.process_name }})</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y box col-span-12 p-5">
        <!-- BEGIN: Form Layout -->
        <div class="">

          <table class="table border-2 pt-5">

            <thead>
                <tr class="border">
                  <th>NO</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Rate</th>
                  <th>Rate Type</th>
                  <th>Rate Count</th>
                  <th v-if="page_data.process_details?.transaction_type == 2">Type</th>
                  <th v-if="page_data.process_details?.process_type == 'makeable'">Shape</th>
                  <th v-if="page_data.process_details?.process_type == 'makeable'">Color</th>
                  <th v-if="page_data.process_details?.process_type == 'makeable'">Purity</th>
                  <th v-if="page_data.process_details?.process_type == 'makeable'">Cut</th>
                  <th v-if="page_data.process_details?.process_type == 'makeable'">Polish</th>
                  <th v-if="page_data.process_details?.process_type == 'makeable'">Symmetry</th>
                  <th>Stone</th>
                  <th>Remove</th>
                </tr>
            </thead>
            <tbody class="border" v-if="page_data.process_details?.process_type == 'makeable'">

              <tr class="" v-for="(item,index) in page_data.form_data" :key="index">
                <td class="whitespace-nowrap border-b">{{ index + 1 }}</td>
                <td class="whitespace-nowrap w-24" style="padding: 2px;"><input type="text" v-model="item.from" class="form-control rounded-none w-full" placeholder="From" aria-describedby="input-group-3"  @keypress="$h.preventInvalidInput" /></td>
                <td class="whitespace-nowrap w-24" style="padding: 2px;"><input type="text" v-model="item.to" class="form-control rounded-none w-full" placeholder="To" aria-describedby="input-group-3"  @keypress="$h.preventInvalidInput" /></td>
                <td class="whitespace-nowrap w-24" style="padding: 2px;"><input type="text" v-model="item.rates" class="form-control rounded-none w-full" placeholder="Rate" aria-describedby="input-group-3"  @keypress="$h.preventInvalidInput" /></td>
                <td class="whitespace-nowrap" style="padding: 2px;">
                  <TomSelect
                  v-model="item.rate_type"
                  :options="{
                    placeholder: 'Rate Type'
                  }"
                  class=""
                  >
                  <option value="1">Issue Weight</option>
                    <option value="2">Receive Weight</option>
                    <option value="3">Polish Weight</option>
                    <option value="4">Fixed Piece</option>
                    <option value="5">Weight Loss</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;">
                  <TomSelect
                  v-model="item.count_type"
                  :options="{
                    placeholder: 'Rate Count'
                  }"
                  class=""
                  >
                  <option value="1">Issue Weight</option>
                    <option value="2">Receive Weight</option>
                    <option value="3">Polish Weight</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;" v-if="page_data.process_details?.transaction_type == 2">
                  <TomSelect
                  v-model="item.type"
                  :options="{
                    placeholder: 'Type'
                  }"
                  class=""
                  >
                  <option value="1">PIE</option>
                  <option value="2">SHOWING</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;" v-if="page_data.process_details?.process_type == 'makeable'">
                  <TomSelect
                  v-model="item.shape"
                  :options="{
                    placeholder: 'Shape'
                  }"
                  class="" multiple
                  >
                  <option value="All">All</option>
                    <option v-for="item in page_data.shapes" :key="item.id" :value="item.id">{{ item.shape_name }}</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;" v-if="page_data.process_details?.process_type == 'makeable'">
                  <TomSelect
                  v-model="item.color"
                  :options="{
                    placeholder: 'Color'
                  }"
                  class="" multiple
                  >
                  <option value="All">All</option>
                  <option v-for="col in page_data.colors" :key="col.id" :value="col.id">{{ col.color_name }}</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;" v-if="page_data.process_details?.process_type == 'makeable'">
                  <TomSelect
                  v-model="item.purity"
                  :options="{
                    placeholder: 'Purity'
                  }"
                  class="" multiple
                  >
                  <option value="All">All</option>
                  <option v-for="col in page_data.purities" :key="col.id" :value="col.id">{{ col.purity_name }}</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;" v-if="page_data.process_details?.process_type == 'makeable'">
                  <TomSelect
                  v-model="item.cut"
                  :options="{
                    placeholder: 'Cut'
                  }"
                  class="" multiple
                  >
                  <option value="All">All</option>
                  <option v-for="col in page_data.cuts" :key="col.id" :value="col.id">{{ col.cut_name }}</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;" v-if="page_data.process_details?.process_type == 'makeable'">
                  <TomSelect
                  v-model="item.polish"
                  :options="{
                    placeholder: 'Polish'
                  }"
                  class="" multiple
                  >
                  <option value="All">All</option>
                  <option v-for="col in page_data.polish" :key="col.id" :value="col.id">{{ col.polish_name }}</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;" v-if="page_data.process_details?.process_type == 'makeable'">
                  <TomSelect
                  v-model="item.symmetry"
                  :options="{
                    placeholder: 'Symmetry'
                  }"
                  class="" multiple
                  >
                  <option value="All">All</option>
                  <option v-for="col in page_data.symmetry" :key="col.id" :value="col.id">{{ col.symmetry_name }}</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;">
                  <TomSelect
                  v-model="item.stone"
                  :options="{
                    placeholder: 'Stone'
                  }"
                  class="" multiple
                  >
                  <option value="All">All</option>
                  <option v-for="col in page_data.stones" :key="col.id" :value="col.id">{{ col.stone_name }}</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;" v-if="index != 0">
                  <button class="btn btn-danger btn-sm py-2" @click="remove_column(item.id)">
                    <Trash2Icon class="h-4 w-4" />
                  </button>
                </td>
              </tr>

            </tbody>
            <tbody class="border" v-if="page_data.process_details?.process_type == 'rough'">

              <tr class="" v-for="(item,index) in page_data.form_data" :key="index">
                <td class="whitespace-nowrap border-b">{{ index + 1 }}</td>
                <td class="whitespace-nowrap w-60" style="padding: 2px;"><input type="text" v-model="item.from" class="form-control rounded-none w-full" placeholder="From" aria-describedby="input-group-3"  @keypress="$h.preventInvalidInput" /></td>
                <td class="whitespace-nowrap w-60" style="padding: 2px;"><input type="text" v-model="item.to" class="form-control rounded-none w-full" placeholder="To" aria-describedby="input-group-3"  @keypress="$h.preventInvalidInput" /></td>
                <td class="whitespace-nowrap w-60" style="padding: 2px;"><input type="text" v-model="item.rates" class="form-control rounded-none w-full" placeholder="Rate" aria-describedby="input-group-3"  @keypress="$h.preventInvalidInput" /></td>
                <td class="whitespace-nowrap" style="padding: 2px;">
                  <TomSelect
                  v-model="item.rate_type"
                  :options="{
                    placeholder: 'Rate Type'
                  }"
                  class="w-full"
                  >
                  <option value="1">Issue Weight</option>
                    <option value="2">Receive Weight</option>
                    <option value="3">Polish Weight</option>
                    <option value="4">Fixed Piece</option>
                    <option value="5">Weight Loss</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;">
                  <TomSelect
                  v-model="item.count_type"
                  :options="{
                    placeholder: 'Rate Count'
                  }"
                  class=""
                  >
                  <option value="1">Issue Weight</option>
                    <option value="2">Receive Weight</option>
                    <option value="3">Polish Weight</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;" v-if="page_data.process_details?.transaction_type == 2">
                  <TomSelect
                  v-model="item.type"
                  :options="{
                    placeholder: 'Type'
                  }"
                  class="w-full"
                  >
                  <option value="1">PIE</option>
                    <option value="2">SHOWING</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;">
                  <TomSelect
                  v-model="item.stone"
                  :options="{
                    placeholder: 'Stone'
                  }"
                  class="w-full" multiple
                  >
                  <option value="All">All</option>
                  <option v-for="col in page_data.stones" :key="col.id" :value="col.id">{{ col.stone_name }}</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;" v-if="index != 0">
                  <button class="btn btn-danger btn-sm py-2" @click="remove_column(item.id)">
                    <Trash2Icon class="h-4 w-4" />
                  </button>
                </td>
              </tr>

            </tbody>
            <tbody class="border" v-if="page_data.process_details?.process_type == 'bunch'">

              <tr class="" v-for="(item,index) in page_data.form_data" :key="index">
                <td class="whitespace-nowrap border-b">{{ index + 1 }}</td>
                <td class="whitespace-nowrap w-60" style="padding: 2px;"><input type="text" v-model="item.from" class="form-control rounded-none w-full" placeholder="From" aria-describedby="input-group-3"  @keypress="$h.preventInvalidInput" /></td>
                <td class="whitespace-nowrap w-60" style="padding: 2px;"><input type="text" v-model="item.to" class="form-control rounded-none w-full" placeholder="To" aria-describedby="input-group-3"  @keypress="$h.preventInvalidInput" /></td>
                <td class="whitespace-nowrap w-60" style="padding: 2px;"><input type="text" v-model="item.rates" class="form-control rounded-none w-full" placeholder="Rate" aria-describedby="input-group-3"  @keypress="$h.preventInvalidInput" /></td>
                <td class="whitespace-nowrap" style="padding: 2px;">
                  <TomSelect
                  v-model="item.rate_type"
                  :options="{
                    placeholder: 'Rate Type'
                  }"
                  class="w-full"
                  >
                  <option value="1">Issue Weight</option>
                    <option value="2">Receive Weight</option>
                    <option value="3">Polish Weight</option>
                    <option value="4">Fixed Piece</option>
                    <option value="5">Weight Loss</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;">
                  <TomSelect
                  v-model="item.count_type"
                  :options="{
                    placeholder: 'Rate Count'
                  }"
                  class=""
                  >
                  <option value="1">Issue Weight</option>
                    <option value="2">Receive Weight</option>
                    <option value="3">Polish Weight</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;" v-if="page_data.process_details?.transaction_type == 2">
                  <TomSelect
                  v-model="item.type"
                  :options="{
                    placeholder: 'Type'
                  }"
                  class="w-full"
                  >
                  <option value="1">PIE</option>
                    <option value="2">SHOWING</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;">
                  <TomSelect
                  v-model="item.stone"
                  :options="{
                    placeholder: 'Stone'
                  }"
                  class="w-full" multiple
                  >
                  <option value="All">All</option>
                  <option v-for="col in page_data.stones" :key="col.id" :value="col.id">{{ col.stone_name }}</option>
                  </TomSelect>
                </td>
                <td class="whitespace-nowrap" style="padding: 2px;" v-if="index != 0">
                  <button class="btn btn-danger btn-sm py-2" @click="remove_column(item.id)">
                    <Trash2Icon class="h-4 w-4" />
                  </button>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <div class="flex justify-between items-center">

          <div>
            <button class="btn btn-primary btn-sm p-2" @click="add_column">
              <PlusIcon class="h-4 w-4 mr-2" /> Add
            </button>
          </div>
          <div class="text-right mt-3">
            <router-link :to="{ name: 'process' }" class="btn btn-outline-secondary w-24 mr-1">
              Cancel
            </router-link>
            <button type="button" @click="submit_form" :disabled="page_data.submit" class="btn btn-primary w-24">Save
              <LoadingIcon v-if="page_data.submit" icon="oval" color="white" class="w-4 h-4 ml-2" />
            </button>
          </div>

        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import router from '@/router'
import axios from 'axios'
import { defineComponent, inject, onMounted, reactive, ref, toRef, watch } from 'vue'
import Toastify from 'toastify-js'
import { useRoute} from 'vue-router'
import { stringify } from 'json5'

export default defineComponent({
  setup() {

    const route = useRoute()
    const swal = inject('$swal')
  const page_data = reactive({
    process_details: [],
    colors: [],
    cuts: [],
    purities: [],
    polish: [],
    shapes: [],
    symmetry: [],
    fluerocents: [],
    tensions: [],
    stones: [],
    column_index: 0,
    submit: false,
    form_data: []
  })

  const add_column = () => {
    page_data.column_index += 1;
    page_data.form_data.push({
      id: page_data.column_index,
      process_id: "",
      from: "",
      to: "",
      rate_type: "1",
      type: "1",
      count_type: "1",
      rates: "",
      color: ["All"],
      cut: ["All"],
      purity: ["All"],
      polish: ["All"],
      shape: ["All"],
      symmetry: ["All"],
      stone: ["All"],
      tension: ["All"],
      fluerocent: ["All"],
      active_status: "",
      processing: ""
    })
  }

  const remove_column = (id) => {
    swal({
        title: "Are you sure?",
        text: "You are about remove rate!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
    }).then(result => {
        if (result.isConfirmed) {
            var i = page_data.form_data.length;
            while (i--) {
                if (page_data.form_data[i]['id'] == id) {
                  page_data.form_data.splice(i, 1);
                }
            }
        }
    });
  }

  function get_all_parameters() {
      axios({
          url: 'api/master/get_all_parameters',
          method: 'post',
          baseURL: BASE_URL,
          headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          config: {
              headers: {
                  'Accept': 'application/json'
              }
          }
      }).then((res) => {
          page_data.colors = res.data.color
          page_data.cuts = res.data.cut
          page_data.purities = res.data.purity
          page_data.polish = res.data.polish
          page_data.shapes = res.data.shape
          page_data.symmetry = res.data.symmetry
          page_data.fluerocents = res.data.fluerocent
          page_data.tensions = res.data.tension
          page_data.stones = res.data.stone
          // purchase.stone_id = screenData.stones[0].id
          // screenData.labs = res.data.lab
      })
  }

  function get_process_details() {
      axios({
          url: 'api/master/empr-gt',
          method: 'post',
          baseURL: BASE_URL,
          data: {
            id: route.params.id
          },
          headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
          },
          config: {
              headers: {
                  'Accept': 'application/json'
              }
          }
      }).then((res) => {
          page_data.process_details = res.data.process_details

          if(res.data.items.length > 0) {
            page_data.form_data = res.data.items

            page_data.form_data.forEach(element => {
              element.rate_type = ''+ element.rate_type
              element.count_type = ''+ element.count_type
              element.type = ''+ element.type
            });
          } else {
            add_column()
          }
      })
  }

  const submit_form = () => {
    swal({
        title: "Are you sure?",
        text: "You are about update rates!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Save",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        allowOutsideClick: () => !$Swal.isLoading()
    }).then(result => {
        if (result.isConfirmed) {

          const params = {
            id: route.params.id,
            rates: page_data.form_data
          }

          page_data.submit = true

          let promise = axios({
            url: "api/master/empr-st",
            method: "POST",
            data: params,
            baseURL: BASE_URL,
            headers: { Authorization: "Bearer " + localStorage.getItem("token"),
 },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {

              page_data.submit = false
              if (data.status == 'success') {
                swal("Success!", data.msg, "success");
              } else {

                  swal("Warning!", data.msg, "warning");
                }

              })
              .catch(_error => {
                page_data.submit = false
                swal("Warning!", "Something went wrong.", "warning");

            });
        }
    });
  }

  onMounted(() => {
    get_process_details()
    get_all_parameters()
  })

  return {
    page_data,
    add_column,
    remove_column,
    submit_form
  }
  }
})
</script>
