<template>
  <div id="basic-non-sticky-notification-packet-receive" class="toastify-content hidden flex flex-col sm:flex-row">
            <div class="flex items-center justify-around">
              <CheckCircleIcon class="w-6 h-6 text-theme-9 mx-auto" />
              <div class="font-medium text-black">Packet Closing is Added</div>
            </div>
        </div>
    <div>
      <div class="intro-y flex flex-col sm:flex-row items-center mt-3">
        <div class="w-full sm:w-auto flexsm:mt-0">
        </div>
      </div>
      <div class="pos intro-y grid">
        <!-- BEGIN: Item List -->
        <div class="intro-y col-span-12">
          <div class="grid grid-cols-12 gap-3 mt-1">
            <div class="col-span-4 sm:col-span-3 md:col-span-2 box text-center cursor-pointer zoom-in p-1" v-for="(item,index) in pagedata.department_closings" :key="index" @click="getDepartmentClosing(item.id)">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">{{ item.department_name }}</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-1">{{ item.total_packets }}</span>
                  <span class="py-1 rounded-lg text-primary-4">{{ item.total_received }}</span> / <span class="mr-1 py-1 rounded-lg text-primary-3">{{ item.total_missing }}</span></div>
              </div>
            </div>
            <div class="col-span-4 sm:col-span-3 md:col-span-2 box text-center cursor-pointer zoom-in p-1">
                <div>
                  <div class="whitespace-nowrap overflow-hidden">Total Issue</div>
                  <div class="text-md">
                    <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-1">{{ pagedata.closings_total.total_packets }}</span>
                  </div>
                </div>
            </div>
            <div class="col-span-4 sm:col-span-3 md:col-span-2 box text-center cursor-pointer zoom-in p-1">
                <div>
                  <div class="whitespace-nowrap overflow-hidden">Total Receive</div>
                  <div class="text-md">
                    <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-4">{{ pagedata.closings_total.total_received }}</span>
                  </div>
                </div>
            </div>
            <div class="col-span-4 sm:col-span-3 md:col-span-2 box text-center cursor-pointer zoom-in p-1">
                <div>
                  <div class="whitespace-nowrap overflow-hidden">Total Missing</div>
                  <div class="text-md">
                    <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-3">{{ pagedata.closings_total.total_missing }}</span>
                  </div>
                </div>
            </div>
            <div @click="get_external_closing_details('external')" class="col-span-4 sm:col-span-3 md:col-span-2 box text-center cursor-pointer zoom-in p-1">
                <div>
                  <div class="whitespace-nowrap overflow-hidden">External</div>
                  <div class="text-md">
                    <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-9">{{ pagedata.closings_total.total_external }}</span>
                  </div>
                </div>
            </div>
            <div @click="get_external_closing_details('discuss')" class="col-span-4 sm:col-span-3 md:col-span-2 box text-center cursor-pointer zoom-in p-1">
                <div>
                  <div class="whitespace-nowrap overflow-hidden">Discuss</div>
                  <div class="text-md">
                    <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-6">{{ pagedata.closings_total.total_discuss }}</span>
                  </div>
                </div>
            </div>
          </div>
        </div>
        <!-- END: Item List -->
      </div>
      <!-- BEGIN: HTML Table Data -->
      <div class="intro-y box p-5 mt-5">
        <!-- <div class="flex flex-col sm:flex-row sm:items-end xl:items-start"> -->
          <div class="grid grid-cols-12 gap-x-2 items-center">
            <div class="col-span-6 sm:col-span-6 md:col-span-4 lg:col-span-3">
              <div>
              <div class="flex items-center">
                <div class="text-lg font-medium mr-2">Scan Packet</div>
                <div>
                  <input
                        id="remember-me"
                        type="checkbox" v-model="pagedata.check"
                        class="form-check-input border mr-2 bg-white"
                      />
                      <label for="remember-me" class="text-md font-medium">Menual</label>
                </div>
              </div>
              <div class="w-full flex sm:w-auto xl:mt-0 mt-3 sm:ml-auto md:ml-0">
                <div class="relative text-gray-700 dark:text-gray-300 mr-4">
                  <input
                    type="text"
                    v-model="pagedata.search"
                    autofocus
                    @change="getPacketDetailsScan"
                    ref="focusMe"
                    class="form-control box pr-10 placeholder-theme-13"
                    placeholder="Search..."
                  />
                  <SearchIcon
                    class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
                  />
                </div>
                <button class="btn btn-primary shadow-md mr-2" @click="scanAndAddPacket">Add</button>
              </div>
            </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-2">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label"
                  >Select Date</label
                >
                <input
                  type= "date"
                  id="update-profile-form-13"

                  class="form-control"
                  placeholder=""
                  v-model="filterData.date"
                  @change="getDetails"
                />
              </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-2">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label"
                  >Select Department</label
                >
                <TomSelect
                  v-model="filterData.department_id"
                  :options="{
                    placeholder: 'Select Dept',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Department'
                      }
                    }
                  }"
                  @change="changeDepartment"
                  class="w-full"
                >
                <option value="0">Select Dept</option>
                <option v-for="department in pagedata.departments" :key="department.id" :value="department.id">{{department.department_name}}</option>
                </TomSelect>
              </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-2">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label"
                  >Select Manager</label
                >
                <TomSelect
                  v-model="filterData.manager_id"
                  @change="changeManager"
                  :options="{
                    placeholder: 'Select Manager',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Manager'
                      }
                    }
                  }"
                  class="w-full"
                >
                <option value="0">Select Manager</option>
                <option v-for="manager in pagedata.managers" :key="manager.id" :value="manager.id">{{manager.first_name + ' ' + manager.last_name}}</option>
                </TomSelect>
              </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-2">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label"
                  >Select Process</label
                >
                <TomSelect
                  v-model="filterData.process_id"
                  @change="getDetails"
                  :options="{
                    placeholder: 'Select Process',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Process'
                      }
                    }
                  }"
                  class="w-full"
                >
                <option value="0">Select Process</option>
                <option v-for="process in pagedata.processes" :key="process.id" :value="process.id">{{process.process_name}}</option>
                </TomSelect>
              </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-2">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label"
                  >Packet Type</label
                >
                <TomSelect
                  v-model="filterData.pack_type"
                  @change="getDetails"
                  :options="{
                    placeholder: 'Select Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Type'
                      }
                    }
                  }"
                  class="w-full"
                  >
                  <option value="0">Select Type</option>
                  <option value="makeable">Makeable</option>
                  <option value="rough">Rough</option>
                  <option value="bunch">Bunch</option>
                </TomSelect>
              </div>
            </div>
            <div class="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-2">
              <div class="mt-3">
                <label for="update-profile-form-6" class="form-label"
                  >Packet Status</label
                >
                <TomSelect
                  v-model="filterData.status"
                  @change="getDetails"
                  :options="{
                    placeholder: 'Select Type',
                    loadingClass: 'loading',
                    plugins: {
                      dropdown_header: {
                        title: 'Type'
                      }
                    }
                  }"
                  class="w-full"
                  >
                  <option value="0">Select Status</option>
                  <option value="1">Available</option>
                  <option value="2">In Process</option>
                </TomSelect>
              </div>
            </div>
            <div class="col-span-3 sm:col-span-2 md:col-span-2 lg:col-span-1">
              <button
                id="tabulator-html-filter-reset"
                type="button"
                class="btn btn-secondary w-full mt-10"
                @click="onResetFilter"
              >
                Reset
              </button>
            </div>
          </div>
        <!-- </div> -->
        <div class="overflow-x-auto scrollbar-hidden">
          <div
            id="tabulator"
            ref="tableRef"
            class="mt-5 table-report table-report--tabulator"
          ></div>
        </div>
      </div>
      <!-- END: HTML Table Data -->
     <!-- BEGIN: Closing Modal -->
    <div
      id="closing-packet-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body py-5 text-center">
            <!-- BEGIN: HTML Table Data -->
            <div class="intro-y box p-5 mt-5">
              <div class="text-lg font-medium mr-2"><span  v-if="external_filterdata.type == 'external'">External Closing</span><span v-else>Discuss Closing</span></div>
              <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
                <div class="grid grid-cols-12 gap-x-2">

                  <div class="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-3">
                    <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label"
                        >Select Date</label
                      >
                      <input
                        type= "date"
                        id="update-profile-form-13"

                        class="form-control"
                        placeholder=""
                        v-model="external_filterdata.date"
                        @change="initTabulatorExternal"
                      />
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-3">
                    <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label"
                        >Select Department</label
                      >
                      <TomSelect
                        v-model="external_filterdata.department_id"
                        :options="{
                          placeholder: 'Select Department',
                          loadingClass: 'loading',
                          plugins: {
                            dropdown_header: {
                              title: 'Department'
                            }
                          }
                        }"
                        @change="changeExternalDepartment"
                        class="w-full"
                      >
                      <option value="0">Select Department</option>
                      <option v-for="department in pagedata.departments" :key="department.id" :value="department.id">{{department.department_name}}</option>
                      </TomSelect>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-3">
                    <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label"
                        >Select Manager</label
                      >
                      <TomSelect
                        v-model="external_filterdata.manager_id"
                        @change="changeExternalManager"
                        :options="{
                          placeholder: 'Select Manager',
                          loadingClass: 'loading',
                          plugins: {
                            dropdown_header: {
                              title: 'Manager'
                            }
                          }
                        }"
                        class="w-full"
                      >
                      <option value="0">Select Manager</option>
                      <option v-for="manager in pagedata.external_managers" :key="manager.id" :value="manager.id">{{manager.first_name + ' ' + manager.last_name}}</option>
                      </TomSelect>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-3">
                    <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label"
                        >Select Process</label
                      >
                      <TomSelect
                        v-model="external_filterdata.process_id"
                        @change="initTabulatorExternal"
                        :options="{
                          placeholder: 'Select Process',
                          loadingClass: 'loading',
                          plugins: {
                            dropdown_header: {
                              title: 'Process'
                            }
                          }
                        }"
                        class="w-full"
                      >
                      <option value="0">Select Process</option>
                      <option v-for="process in pagedata.external_processes" :key="process.id" :value="process.id">{{process.process_name}}</option>
                      </TomSelect>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-3" v-if="external_filterdata.type == 'external'">
                    <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label"
                        >Select Employee</label
                      >
                      <TomSelect
                        v-model="external_filterdata.employee_id"
                        @change="initTabulatorExternal"
                        :options="{
                          placeholder: 'Select Employee',
                          loadingClass: 'loading',
                          plugins: {
                            dropdown_header: {
                              title: 'Employee'
                            }
                          }
                        }"
                        class="w-full"
                      >
                      <option value="0">Select Employee</option>
                      <option v-for="party in pagedata.jobwork_parties" :key="party.id" :value="party.id">{{party.first_name + ' ' + party.last_name}}</option>
                      </TomSelect>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-3">
                    <div class="mt-3">
                      <label for="update-profile-form-6" class="form-label"
                        >Packet Type</label
                      >
                      <TomSelect
                        v-model="external_filterdata.pack_type"
                        @change="initTabulatorExternal"
                        :options="{
                          placeholder: 'Select Type',
                          loadingClass: 'loading',
                          plugins: {
                            dropdown_header: {
                              title: 'Type'
                            }
                          }
                        }"
                        class="w-full"
                        >
                        <option value="0">Select Type</option>
                        <option value="makeable">Makeable</option>
                        <option value="rough">Rough</option>
                        <option value="bunch">Bunch</option>
                      </TomSelect>
                    </div>
                  </div>
                  <div class="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-3 mt-8">
                    <div class="report-box zoom-in">
                      <div class="box p-2">
                        <div class="flex items-center justify-between">
                          <!-- <ActivityIcon class="report-box__icon text-theme-10" /> -->
                          <div class="text-base text-gray-600"> Packets</div>
                          <div class="text-xl font-medium leading-8">{{ pagedata.external_total }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="overflow-x-auto scrollbar-hidden">
                <div
                  id="tabulator"
                  ref="tableRefExternal"
                  class="mt-5 table-report table-report--tabulator"
                ></div>
              </div>
            </div>
            <!-- END: HTML Table Data -->
          </div>
        </div>
      </div>
    </div>
    <!-- END: Closing Modal -->
    <!-- BEGIN: Department Closing Modal -->
    <div
      id="department-packet-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <a data-dismiss="modal" href="javascript:;">
            <XIcon class="w-8 h-8 text-gray-500" />
          </a>
          <div class="modal-body py-5 text-center">
            <!-- BEGIN: HTML Table Data -->
            <div class="intro-y box p-5 mt-5">
              <div class="text-lg font-medium mr-2">Departmnt Closings</div>
              <div class="overflow-x-auto scrollbar-hidden">
                <div
                  id="tabulator"
                  ref="tableRefDepartment"
                  class="mt-5 table-report table-report--tabulator"
                ></div>
              </div>
            </div>
            <!-- END: HTML Table Data -->
          </div>
        </div>
      </div>
    </div>
    <!-- END: Department Closing Modal -->
    </div>
  </template>
  <script>
  /* eslint-disable */
  import { BASE_URL } from '@/constants/config'
  import store from '@/store'
  import axios from 'axios'
import cash from 'cash-dom'
  import feather from 'feather-icons'
  import moment from 'moment'
  import Tabulator from 'tabulator-tables'
  import Toastify from 'toastify-js'
  import { defineComponent, inject, onMounted, reactive, ref, watch } from 'vue'

  export default defineComponent({
    setup() {
      const focusMe = ref()
      const pagedata = reactive({
        closings_total: [],
        department_closings: [],
        processes: [],
        external_processes: [],
        employees: [],
        departments: [],
        managers: [],
        external_managers: [],
        jobwork_parties: [],
        check: false,
        search: '',
        external_total: ''
      })
      const filterData = reactive({
        date: moment().format('YYYY-MM-DD'),
        process_id: '0',
        department_id: '0',
        manager_id: '0',
        employee_id: '0',
        pack_type: '0',
        status: '0',
      })
      const external_filterdata = reactive({
        date: moment().format('YYYY-MM-DD'),
        process_id: '0',
        department_id: '0',
        manager_id: '0',
        employee_id: '0',
        pack_type: '0',
        type: ''
      })
      const tableRef = ref()
      const tableRefExternal = ref()
      const tableRefDepartment = ref()
      const tabulator = ref()
      const swal = inject('$swal')
      onMounted(() => {
        focusMe.value.focus()
        getPageData(),
        getDepartmentData()
        getManagersData()
        initTabulator()
        getExternalParties()
        getprocessDetails()
      })
      function getPageData() {
        axios({
          url: 'api/closing/admin_closing_total',
          method: 'post',
          baseURL: BASE_URL,
          data: filterData,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { Accept: "application/json" } }
        }).then((res) => {
          pagedata.closings_total = res.data.items
          pagedata.department_closings = res.data.department_closings
        })
      }
      function getDepartmentData() {
        axios({
          url: 'api/master/dp_department',
          method: 'post',
          baseURL: BASE_URL,
          headers: {Authorization: "Bearer " + localStorage.getItem("token"),
},
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.departments = res.data.items
        })
      }
      function getManagersData() {
        let params = {
          department_id: filterData.department_id == '0' ? '' : window.btoa(filterData.department_id)
        }
        let url = ''
        if(filterData.department_id != '0') {

          url = 'api/master/get_manager_by_department'
        } else {
          url = 'api/master/dp_managers'
        }
        axios({
          url: url,
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: {Authorization: "Bearer " + localStorage.getItem("token"),
},
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.managers = res.data.items
        })
      }
      function getExternalManagersData() {
        let params = {
          department_id: external_filterdata.department_id == '0' ? '' : window.btoa(external_filterdata.department_id)
        }
        let url = ''
        if(external_filterdata.department_id != '0') {

          url = 'api/master/get_manager_by_department'
        } else {
          url = 'api/master/dp_managers'
        }
        axios({
          url: url,
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: {Authorization: "Bearer " + localStorage.getItem("token"),
},
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.external_managers = res.data.items
        })
      }
      function getExternalParties() {
        axios({
          url: 'api/master/dp_jober',
          method: 'post',
          baseURL: BASE_URL,
          headers: {Authorization: "Bearer " + localStorage.getItem("token"),
},
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.jobwork_parties = res.data.items
        })
      }
      function getprocessDetails() {
        let params = {
          manager_id: filterData.manager_id == '0' ? '' : filterData.manager_id
        }
        let url = ''
        if(filterData.manager_id != '0') {

          url = 'api/master/get_process_by_manager'
        } else {
          url = 'api/master/dp_process'
        }
        axios({
          url: url,
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: {Authorization: "Bearer " + localStorage.getItem("token"),
},
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.processes = res.data.items
        })
      }
      function getExternalprocessDetails() {
        let params = {
          manager_id: external_filterdata.manager_id == '0' ? '' : external_filterdata.manager_id
        }
        let url = ''
        if(external_filterdata.manager_id != '0') {

          url = 'api/master/get_process_by_manager'
        } else {
          url = 'api/master/dp_process'
        }
        axios({
          url: url,
          method: 'post',
          baseURL: BASE_URL,
          data: params,
          headers: {Authorization: "Bearer " + localStorage.getItem("token"),
},
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.external_processes = res.data.items
        })
      }
      function scanAndAddPacket() {
        if(pagedata.search != '' && pagedata.search != null){
            const params = {
              number : window.btoa(pagedata.search)
            }
            axios({
              url: 'api/closing/scan_closings_packet',
              method: 'post',
              baseURL: BASE_URL,
              data: params,
              headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
              config: { headers: { 'Accept':'application/json' } }
            }).then(() => {
              basicNonStickyNotificationPacketReceive()
              // swal("Success!", res.data.success, "success")
              pagedata.search = ''
              getDetails()
            }).catch((err) => {
              swal("Warning!", err.response.data.error, "warning")
              pagedata.search = ''
            })
          }
      }
      const initTabulator = () => {
        tabulator.value = new Tabulator(tableRef.value, {
          ajaxURL: BASE_URL + 'api/closing/admin_closing_details',
          ajaxConfig:{
              method:"GET", //set request type to Position
              headers: {
                  "Content-type": 'application/json; charset=utf-8', //set specific content type
                  "Authorization": "Bearer " + localStorage.getItem("token")
              },
          },
          ajaxParams: filterData,
          ajaxFiltering: true,
          ajaxSorting: true,
          printAsHtml: true,
          printStyled: true,
          headerSort: false,
          pagination: 'remote',
          paginationSize: 20,
          paginationSizeSelector: [20, 30, 40],
          layout: 'fitColumns',
          responsiveLayout: 'collapse',
          placeholder: 'No matching records found',
          columns: [
            {
              formatter: 'responsiveCollapse',
              width: 40,
              minWidth: 30,
              hozAlign: 'center',
              resizable: false,
              headerSort: false
            },

            // For HTML table
            {
              title: 'Packet No',
              minWidth: 100,
              responsive: 0,
              field: 'number',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().packet_code
                  }</div>
                </div>`
              }
            },
            {
            title: 'Stock No',
            minWidth: 100,
            responsive: 0,
            field: 'number',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().stock_no
                }</div>
              </div>`
            }
          },
            {
              title: 'Department',
              minWidth: 100,
              field: 'department_name',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().department_name ? cell.getData().department_name : '-'
                  }</div>
                </div>`
              }
            },
            {
              title: 'Manager',
              minWidth: 100,
              field: 'manager_name',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().manager_first_name + ' ' + cell.getData().manager_last_name
                  }</div>
                </div>`
              }
            },
            {
              title: 'Process',
              minWidth: 100,
              field: 'process_code',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().process_name ? cell.getData().process_name : '-'
                  }</div>
                </div>`
              }
            },
            {
              title: 'Employee',
              minWidth: 100,
              field: 'employee',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().process_id ? (cell.getData().transaction_type == 3 || cell.getData().transaction_type == 4 ?  cell.getData().planner_first_name + ' ' + cell.getData().planner_last_name: cell.getData().employee_first_name + ' ' + cell.getData().employee_last_name) : '-'
                  }</div>
                </div>`
              }
            },
            {
              title: 'Scanned',
              field: 'stock',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `
                  ${ cell.getData().status == 0 ? '<div class="text-center p-1 rounded-md bg-primary-6"> <i data-feather="info" class="w-4 h-4 text-white"></i> </div>' : '<div class="text-center p-1 rounded-md bg-primary-4"> <i data-feather="check" class="w-4 h-4 text-white"></i> </div>' }`
              }
            },
          ],
          renderComplete() {
            feather.replace({
              'stroke-width': 1.5
            })
          }
        })
      }
      const initTabulatorExternal = () => {
        getExternalTotal()
        if(external_filterdata.type == 'external') {
          tabulator.value = new Tabulator(tableRefExternal.value, {
            ajaxURL: BASE_URL + 'api/closing/admin_out_closing_details',
            ajaxConfig:{
                method:"GET", //set request type to Position
                headers: {
                    "Content-type": 'application/json; charset=utf-8', //set specific content type
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            },
            ajaxParams: external_filterdata,
            ajaxFiltering: true,
            ajaxSorting: true,
            printAsHtml: true,
            printStyled: true,
            headerSort: false,
            pagination: 'remote',
            paginationSize: 20,
            paginationSizeSelector: [20, 30, 40],
            layout: 'fitColumns',
            responsiveLayout: 'collapse',
            placeholder: 'No matching records found',
            columns: [
              {
                formatter: 'responsiveCollapse',
                width: 40,
                minWidth: 30,
                hozAlign: 'center',
                resizable: false,
                headerSort: false
              },

              // For HTML table
              {
                title: 'Packet No',
                minWidth: 100,
                responsive: 0,
                field: 'number',
                vertAlign: 'middle',
                print: false,
                download: false,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      cell.getData().packet_code
                    }</div>
                  </div>`
                }
              },
              {
                title: 'Stock No',
                minWidth: 100,
                responsive: 0,
                field: 'number',
                vertAlign: 'middle',
                print: false,
                download: false,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      cell.getData().stock_no
                    }</div>
                  </div>`
                }
              },
              {
              title: 'Department',
              minWidth: 100,
              field: 'department_name',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().department_name ? cell.getData().department_name : '-'
                  }</div>
                </div>`
              }
            },
            {
              title: 'Manager',
              minWidth: 100,
              field: 'manager_name',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().manager_first_name + ' ' + cell.getData().manager_last_name
                  }</div>
                </div>`
              }
            },
              {
                title: 'Process',
                minWidth: 100,
                field: 'process_code',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: false,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      cell.getData().process_name ? cell.getData().process_name : '-'
                    }</div>
                  </div>`
                }
              },
              {
                title: 'Jobwork Party',
                minWidth: 100,
                field: 'employee',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: false,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      cell.getData().jober_first_name + ' ' + cell.getData().jober_last_name
                    }</div>
                  </div>`
                }
              },
              // {
              //   title: 'Scanned',
              //   field: 'stock',
              //   hozAlign: 'center',
              //   vertAlign: 'middle',
              //   print: false,
              //   download: false,
              //   formatter(cell) {
              //     return `
              //       ${ cell.getData().status == 0 ? '<div class="text-center p-1 rounded-md bg-primary-6"> <i data-feather="info" class="w-4 h-4 text-white"></i> </div>' : '<div class="text-center p-1 rounded-md bg-primary-4"> <i data-feather="check" class="w-4 h-4 text-white"></i> </div>' }`
              //   }
              // }
            ],
            renderComplete() {
              feather.replace({
                'stroke-width': 1.5
              })
            }
          })
        } else {
          tabulator.value = new Tabulator(tableRefExternal.value, {
            ajaxURL: BASE_URL + 'api/closing/admin_out_closing_details',
            ajaxConfig:{
                method:"GET", //set request type to Position
                headers: {
                    "Content-type": 'application/json; charset=utf-8', //set specific content type
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
            },
            ajaxParams: external_filterdata,
            ajaxFiltering: true,
            ajaxSorting: true,
            printAsHtml: true,
            printStyled: true,
            headerSort: false,
            pagination: 'remote',
            paginationSize: 20,
            paginationSizeSelector: [20, 30, 40],
            layout: 'fitColumns',
            responsiveLayout: 'collapse',
            placeholder: 'No matching records found',
            columns: [
              {
                formatter: 'responsiveCollapse',
                width: 40,
                minWidth: 30,
                hozAlign: 'center',
                resizable: false,
                headerSort: false
              },

              // For HTML table
              {
                title: 'Packet No',
                minWidth: 100,
                responsive: 0,
                field: 'number',
                vertAlign: 'middle',
                print: false,
                download: false,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      cell.getData().packet_code
                    }</div>
                  </div>`
                }
              },
              {
                title: 'Stock No',
                minWidth: 100,
                responsive: 0,
                field: 'number',
                vertAlign: 'middle',
                print: false,
                download: false,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      cell.getData().stock_no
                    }</div>
                  </div>`
                }
              },
              {
              title: 'Department',
              minWidth: 100,
              field: 'department_name',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().department_name ? cell.getData().department_name : '-'
                  }</div>
                </div>`
              }
            },
            {
              title: 'Manager',
              minWidth: 100,
              field: 'manager_name',
              hozAlign: 'center',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().manager_first_name + ' ' + cell.getData().manager_last_name
                  }</div>
                </div>`
              }
            },
              {
                title: 'Process',
                minWidth: 100,
                field: 'process_code',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: false,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      cell.getData().process_name ? cell.getData().process_name : '-'
                    }</div>
                  </div>`
                }
              },
              {
                title: 'Employee',
                minWidth: 100,
                field: 'employee',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: false,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      cell.getData().transaction_type == 3 || cell.getData().transaction_type == 4 ?  cell.getData().planner_first_name + ' ' + cell.getData().planner_last_name : cell.getData().external == 1 ? (cell.getData().jober_first_name + ' ' + cell.getData().jober_last_name) : (cell.getData().employee_first_name + ' ' + cell.getData().employee_last_name)
                    }</div>
                  </div>`
                }
              },
              {
                title: 'Discuss User',
                minWidth: 100,
                field: 'employee',
                hozAlign: 'center',
                vertAlign: 'middle',
                print: false,
                download: false,
                formatter(cell) {
                  return `<div>
                    <div class="whitespace-nowrap">${
                      cell.getData().discuss_user_first_name + ' ' + cell.getData().discuss_user_last_name
                    }</div>
                  </div>`
                }
              },
              // {
              //   title: 'Scanned',
              //   field: 'stock',
              //   hozAlign: 'center',
              //   vertAlign: 'middle',
              //   print: false,
              //   download: false,
              //   formatter(cell) {
              //     return `
              //       ${ cell.getData().status == 0 ? '<div class="text-center p-1 rounded-md bg-primary-6"> <i data-feather="info" class="w-4 h-4 text-white"></i> </div>' : '<div class="text-center p-1 rounded-md bg-primary-4"> <i data-feather="check" class="w-4 h-4 text-white"></i> </div>' }`
              //   }
              // }
            ],
            renderComplete() {
              feather.replace({
                'stroke-width': 1.5
              })
            }
          })
        }
      }
      const initTabulatorDepartment = (id) => {
        tabulator.value = new Tabulator(tableRefDepartment.value, {
          ajaxURL: BASE_URL + 'api/closing/department_wise_closing_total',
          ajaxConfig:{
              method:"GET", //set request type to Position
              headers: {
                  "Content-type": 'application/json; charset=utf-8', //set specific content type
                  "Authorization": "Bearer " + localStorage.getItem("token")
              },
          },
          ajaxParams: {
            date : filterData.date,
            department_id: id
          },
          ajaxFiltering: true,
          ajaxSorting: true,
          printAsHtml: true,
          printStyled: true,
          headerSort: false,
          pagination: 'remote',
          paginationSize: 20,
          paginationSizeSelector: [20, 30, 40],
          layout: 'fitColumns',
          responsiveLayout: 'collapse',
          placeholder: 'No matching records found',
          columns: [
            {
              formatter: 'responsiveCollapse',
              width: 40,
              minWidth: 30,
              hozAlign: 'center',
              resizable: false,
              headerSort: false
            },

            // For HTML table
            {
              title: 'Manager',
              minWidth: 100,
              responsive: 0,
              field: 'number',
              vertAlign: 'middle',
              print: false,
              download: false,
              formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().manager_first_name + ' ' + cell.getData().manager_last_name
                  }</div>
                </div>`
              }
            },
            {
            title: 'Total Issue',
            minWidth: 80,
            field: 'total_packets',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().total_packets ? cell.getData().total_packets : '-'
                  }</div>
                </div>`
              }
          },
            {
            title: 'Total Received',
            minWidth: 80,
            field: 'total_received',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().total_received ? cell.getData().total_received : '-'
                  }</div>
                </div>`
              }
          },
            {
            title: 'Total Missing',
            minWidth: 80,
            field: 'total_missing',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().total_missing ? cell.getData().total_missing : '-'
                  }</div>
                </div>`
              }
          },
            {
            title: 'Total External',
            minWidth: 80,
            field: 'total_external',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().total_external ? cell.getData().total_external : '-'
                  }</div>
                </div>`
              }
          },
            {
            title: 'Total Discuss',
            minWidth: 80,
            field: 'total_discuss',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
                return `<div>
                  <div class="whitespace-nowrap">${
                    cell.getData().total_discuss ? cell.getData().total_discuss : '-'
                  }</div>
                </div>`
              }
          },
          ],
          renderComplete() {
            feather.replace({
              'stroke-width': 1.5
            })
          }
        })
      }
      function getExternalTotal() {
      axios({
            url: 'api/closing/admin_out_closing_details_total',
            method: 'post',
            baseURL: BASE_URL,
            data: external_filterdata,
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
            config: { headers: { 'Accept':'application/json' } }
          }).then((res) => {
            pagedata.external_total = res.data.total.total_packets
          })
    }
      function getDetails() {
        initTabulator()
        getPageData()
      }
      // Redraw table onresize
      const reInitOnResizeWindow = () => {
        window.addEventListener('resize', () => {
          tabulator.value.redraw()
          feather.replace({
            'stroke-width': 1.5
          })
        })
      }
      function get_external_closing_details(type) {
        external_filterdata.type = type
        initTabulatorExternal()
        getExternalManagersData()
        getExternalprocessDetails()
        setTimeout(() => {
          cash('#closing-packet-modal').modal('show')
        }, 50);
      }
        function changeDepartment() {
          filterData.manager_id = '0'
          getManagersData()
          getDetails()
        }
        function changeManager() {
          filterData.process_id = '0'
          getprocessDetails()
          getDetails()
        }
        function changeExternalDepartment() {
          external_filterdata.manager_id = '0'
          getExternalManagersData()
          initTabulatorExternal()
        }
        function changeExternalManager() {
          external_filterdata.process_id = '0'
          getExternalprocessDetails()
          initTabulatorExternal()
        }
        const basicNonStickyNotificationPacketReceive = () => {
        Toastify({
          node: cash('#basic-non-sticky-notification-packet-receive')
            .clone()
            .removeClass('hidden')[0],
          duration: 5000,
          newWindow: true,
          close: true,
          gravity: 'top',
          position: 'right',
          stopOnFocus: true
        }).showToast()
      }
      function onResetFilter() {
        filterData.date = moment().format('YYYY-MM-DD')

        if(filterData.process_id == '0' && filterData.manager_id == '0' && filterData.department_id == '0' && filterData.employee_id == '0' && filterData.pack_type == '0' && filterData.status == '0') {
          filterData.process_id = '0'
          filterData.manager_id = '0'
          filterData.department_id = '0'
          filterData.employee_id = '0'
          filterData.pack_type = '0'
          filterData.status = '0'
        } else {
          getDetails()
        }
      }
        function getPacketDetailsScan() {
        if(!pagedata.check && pagedata.search) {
          scanAndAddPacket();
        }
      }
      function getDepartmentClosing(id) {
        cash('#department-packet-modal').modal('show')
        setTimeout(() => {
          initTabulatorDepartment(id)
        }, 10)
      }
      return {
        focusMe,
        pagedata,
        filterData,
        external_filterdata,
        tableRef,
        tableRefExternal,
        tableRefDepartment,
        getPacketDetailsScan,
        scanAndAddPacket,
        reInitOnResizeWindow,
        get_external_closing_details,
        getDetails,
        initTabulatorExternal,
        getExternalParties,
        basicNonStickyNotificationPacketReceive,
        onResetFilter,
        changeDepartment,
        getDepartmentClosing,
        changeManager,
        changeExternalDepartment,
        changeExternalManager,
      }
    }
  })
  </script>
