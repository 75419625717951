<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-3">
      <div class="w-full sm:w-auto flexsm:mt-0">
      </div>
    </div>
    <div class="pos intro-y grid">
      <!-- BEGIN: Item List -->
      <div class="intro-y col-span-12">
        <div class="grid grid-cols-12 gap-3 mt-1">
          <div @click="getManagerData(data.id, data.name)" class="col-span-4 sm:col-span-3 md:col-span-2 lg:col-span-1 box text-center cursor-pointer zoom-in p-1" v-for="(data,index) in pagedata.departments" :key="index">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">{{ data.name }}</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-1">{{ data.total }}</span>
                  <span class="py-1 rounded-lg text-primary-4">{{ data.received }}</span> / <span class="mr-1 py-1 rounded-lg text-primary-3">{{ data.missing }}</span></div>
              </div>
          </div>
          <div class="col-span-4 sm:col-span-3 md:col-span-2 lg:col-span-1 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">Total Issue</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-1">{{ pagedata.total_issue }}</span>
                </div>
              </div>
          </div>
          <div class="col-span-4 sm:col-span-3 md:col-span-2 lg:col-span-1 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">Total Receive</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-4">{{ pagedata.total_receive }}</span>
                </div>
              </div>
          </div>
          <div class="col-span-4 sm:col-span-3 md:col-span-2 lg:col-span-1 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">Total Missing</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-3">{{ pagedata.total_missing }}</span>
                </div>
              </div>
          </div>
          <div @click="getExternalData('external')" class="col-span-4 sm:col-span-3 md:col-span-2 lg:col-span-1 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">External</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-9">{{ pagedata.external }}</span>
                </div>
              </div>
          </div>
          <div @click="getExternalData('discuss')" class="col-span-4 sm:col-span-3 md:col-span-2 lg:col-span-1 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="text-xs whitespace-nowrap overflow-hidden">Discuss</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-6">{{ pagedata.discuss }}</span>
                    <span  v-if="pagedata.discuss_receive || pagedata.discuss_missing" class="py-1 rounded-lg text-primary-4">{{ pagedata.discuss_receive }}</span><span v-if="pagedata.discuss_receive || pagedata.discuss_missing"> / </span><span v-if="pagedata.discuss_receive || pagedata.discuss_missing" class="mr-1 py-1 rounded-lg text-primary-3">{{ pagedata.discuss_missing }}</span>
                </div>
              </div>
          </div>
        </div>
      </div>
      <!-- END: Item List -->
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class=" flex items-center mt-2 xl:mt-0">
            <div class="input-group mt-2 sm:mt-0">
              <Litepicker
                  v-model="filter.date"
                  :options="{
                    autoApply: false,
                    showWeekNumbers: true,
                    dropdowns: {
                      minYear: 1990,
                      maxYear: null,
                      months: true,
                      years: false,
                    },
                  }"
                  class="form-control block mx-auto mr-3"
                />
            </div>
              <div class="mr-3">
                <TomSelect
                  v-model="filter.dept"
                  @change="getManagersandFilter('internal')"
                  :options="{
                    placeholder: 'Select your favorite actors'
                  }"
                  class="w-44"
                >
                  <option value="0">Select Department</option>
                  <option v-for="dept in pagedata.department" :key="dept.id" :value="dept.id">{{ dept.name }}</option>
                </TomSelect>
              </div>
              <div class="mr-3">
                <TomSelect
                  v-model="filter.man"
                  @change="initTabulator"
                  :options="{
                    placeholder: 'Select your favorite actors'
                  }"
                  class="w-44"
                >
                  <option value="0">Select Manager</option>
                  <option v-for="dept in pagedata.manager" :key="dept.id" :value="dept.id">{{ dept.name }}</option>
                </TomSelect>
              </div>
              <div>
                <TomSelect
                  v-model="filter.party"
                  @change="initTabulator"
                  :options="{
                    placeholder: 'Select your favorite actors'
                  }"
                  class="w-44"
                >
                  <option value="0">JobWork Party</option>
                  <option v-for="dept in pagedata.parties" :key="dept.id" :value="dept.id">{{ dept.firmname }}</option>
                </TomSelect>
              </div>
              <div class="w-48 relative text-gray-700 dark:text-gray-300 ml-4">
              <select
                  class="form-select"
                  v-model="pagedata.type"
                  @change="initTabulator"
                  aria-label="Default select example"
                >
                  <option value="">Select Type</option>
                  <option value="makeable">Makeable</option>
                  <option value="rough">Rough</option>
                  <option value="bunch">Bunch</option>
                  <!-- <option value="lab">Lab</option> -->
                </select>
            </div>
          </div>
        </form>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Closing Modal -->
  <div
    id="department-manager-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body p-10 text-center">
          <h2 class="text-lg font-medium mr-auto">{{ pagedata.index}}</h2>
          <div class="intro-y box">
            <div class="grid grid-cols-12 gap-3 mt-1">
              <div class="col-span-4 md:col-span-3 lg:col-span-3 box text-center cursor-pointer zoom-in p-1" v-for="(data,index) in pagedata.managers" :key="index">
                <div>
                  <div class="text-xs whitespace-nowrap overflow-hidden">{{ data.name }}</div>
                  <div class="text-md">
                    <span class="text-md mr-2 px-1 rounded-lg text-white bg-primary-1">{{ data.total }}</span>
                    <span class="py-1 rounded-lg text-primary-4">{{ data.received }}</span> / <span class="mr-1 py-1 rounded-lg text-primary-3">{{ data.missing }}</span></div>
                </div>
              </div>
              <div class="col-span-4 md:col-span-3 lg:col-span-3 box text-center cursor-pointer zoom-in p-1">
                  <div>
                    <div class="text-xs whitespace-nowrap overflow-hidden">Total Issue</div>
                    <div class="text-md">
                      <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-1">{{ pagedata.manager_total_issue }}</span>
                    </div>
                  </div>
              </div>
              <div class="col-span-4 md:col-span-3 lg:col-span-3 box text-center cursor-pointer zoom-in p-1">
                  <div>
                    <div class="text-xs whitespace-nowrap overflow-hidden">Total Receive</div>
                    <div class="text-md">
                      <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-4">{{ pagedata.manager_total_receive }}</span>
                    </div>
                  </div>
              </div>
              <div class="col-span-4 md:col-span-3 lg:col-span-3 box text-center cursor-pointer zoom-in p-1">
                  <div>
                    <div class="text-xs whitespace-nowrap overflow-hidden">Total Missing</div>
                    <div class="text-md">
                      <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-3">{{ pagedata.manager_total_missing }}</span>
                    </div>
                  </div>
              </div>
              <div class="col-span-4 md:col-span-3 lg:col-span-3 box text-center cursor-pointer zoom-in p-1">
                  <div>
                    <div class="text-xs whitespace-nowrap overflow-hidden">External</div>
                    <div class="text-md">
                      <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-9">{{ pagedata.manager_external }}</span>
                    </div>
                  </div>
              </div>
              <div class="col-span-4 md:col-span-3 lg:col-span-3 box text-center cursor-pointer zoom-in p-1">
                  <div>
                    <div class="text-xs whitespace-nowrap overflow-hidden">Discuss</div>
                    <div class="text-md">
                      <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-6">{{ pagedata.manager_discuss }}</span>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Closing Modal -->
    <!-- BEGIN: Closing Modal -->
  <div
    id="closing-packet-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body py-5 text-center">
          <!-- BEGIN: HTML Table Data -->
          <div class="intro-y box p-5 mt-5">
            <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
              <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
                <div class=" flex items-center mt-2 xl:mt-0">
                  <div class="input-group mt-2 sm:mt-0">
                    <Litepicker
                        v-model="filterExternal.date"
                        :options="{
                          autoApply: false,
                          showWeekNumbers: true,
                          dropdowns: {
                            minYear: 1990,
                            maxYear: null,
                            months: true,
                            years: false,
                          },
                        }"
                        class="form-control block mx-auto mr-3"
                      />
                  </div>
                    <div class="mr-3">
                      <TomSelect
                        v-model="filterExternal.dept"
                        @change="getManagersandFilter('external')"
                        :options="{
                          placeholder: 'Select your favorite actors'
                        }"
                        class="w-44"
                      >
                        <option value="0">Select Department</option>
                        <option v-for="dept in pagedata.department" :key="dept.id" :value="dept.id">{{ dept.name }}</option>
                      </TomSelect>
                    </div>
                    <div class="mr-3">
                      <TomSelect
                        v-model="filterExternal.man"
                        @change="initTabulatorExternal"
                        :options="{
                          placeholder: 'Select your favorite actors'
                        }"
                        class="w-44"
                      >
                        <option value="0">Select Manager</option>
                        <option v-for="dept in pagedata.managerExternal" :key="dept.id" :value="dept.id">{{ dept.name }}</option>
                      </TomSelect>
                    </div>
                    <div>
                      <TomSelect
                        v-model="filterExternal.party"
                        @change="initTabulatorExternal"
                        :options="{
                          placeholder: 'Select your favorite actors'
                        }"
                        class="w-44"
                      >
                        <option value="0">JobWork Party</option>
                        <option v-for="dept in pagedata.parties" :key="dept.id" :value="dept.id">{{ dept.firmname }}</option>
                      </TomSelect>
                    </div>
                </div>
              </form>
            </div>
            <div class="overflow-x-auto scrollbar-hidden">
              <div
                id="tabulator"
                ref="tableRefExternal"
                class="mt-5 table-report table-report--tabulator"
              ></div>
            </div>
          </div>
          <!-- END: HTML Table Data -->
        </div>
      </div>
    </div>
  </div>
  <!-- END: Closing Modal -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import store from '@/store'
import axios from 'axios'
import feather from 'feather-icons'
import moment from 'moment'
import Tabulator from 'tabulator-tables'
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'

export default defineComponent({
  setup() {
    const pagedata = reactive({
      departments: [],
      total_issue: '',
      total_receive: '',
      total_missing: '',
      date: '',
      external: '',
      discuss: '',
      discuss_receive: '',
      discuss_missing: '',
      managers: [],
      manager_total_issue: '',
      manager_total_receive: '',
      manager_total_missing: '',
      manager_date: '',
      manager_external: '',
      manager_discuss: '',
      department: [],
      manager: [],
      managerExternal: [],
      parties: [],
      index: '',
      type: ''
    })
    const status = computed(() => {
      store.state.status
    })
    onMounted(() => {
      document.querySelector('[id="topbar_search"]').focus();
      getDepartmentData(),
      initTabulator()
      setTimeout(() => {
        getDepartments()
        getExternalParties()
      }, 500);
    })
    function getDepartmentData() {
      axios({
          url: '/closing/admin-closing-department',
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        }).then((res) => {
          pagedata.departments = res.data.departments
          pagedata.total_issue = res.data.total_issue
          pagedata.total_receive = res.data.total_received
          pagedata.total_missing = res.data.total_missing
          pagedata.discuss_receive = res.data.discuss_received
          pagedata.discuss_missing = res.data.discuss_missing
          pagedata.date = res.data.date
          pagedata.external = res.data.external
          pagedata.discuss = res.data.discuss
        })
    }
    function getDepartments() {
      axios({
        url: '/allDepartments',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.department = res.data.departments
      })
    }
    function getExternalParties() {
      axios({
        url: '/get-external-parties',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.parties = res.data.party
      })
    }
    function getManagerData(id, index) {
      axios({
        url: `/closing/admin-closing-manager/${id}`,
        method: 'get',
        baseURL: BASE_URL,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.managers = res.data.managers
        pagedata.manager_total_issue = res.data.total_issue
        pagedata.manager_total_receive = res.data.total_received
        pagedata.manager_total_missing = res.data.total_missing
        pagedata.manager_date = res.data.date
        pagedata.manager_external = res.data.external
        pagedata.manager_discuss = res.data.discuss
        pagedata.index = index
        console.warn(pagedata.index)
        setTimeout(() => {
          cash('#department-manager-modal').modal('show')
        },100)
      })
    }
    function getExternalData(data) {
      filterExternal.type = data
      initTabulatorExternal()
      setTimeout(() => {
        cash('#closing-packet-modal').modal('show')
      },100)
    }
    const tableRef = ref()
    const tableRefExternal = ref()
    const tabulator = ref()
    const filter = reactive({
      man: '0',
      dept: '0',
      party: '0',
      type: 'all',
      date: moment().format('YYYY-MM-DD')
    })
    const filterExternal = reactive({
      man: '0',
      dept: '0',
      party: '0',
      type: '',
      date: moment().format('YYYY-MM-DD')
    })

    const initTabulator = () => {
      const date = moment(filter.date).format('YYYY-MM-DD')
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + `/closing/closing-packets?type=${filter.type}&man=${filter.man}&dept=${filter.dept}&date=${date}&party=${filter.party}&diamond_type=${pagedata.type}`,
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Packet No',
            minWidth: 100,
            responsive: 0,
            field: 'number',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().number
                }</div>
              </div>`
            }
          },
          {
            title: 'Manager',
            minWidth: 100,
            responsive: 0,
            field: 'manager',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
          },
          {
            title: 'Department',
            minWidth: 150,
            field: 'department',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Process',
            minWidth: 100,
            field: 'process',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().process ? cell.getData().process : 'Hold'
                }</div>
              </div>`
            }
          },
          {
            title: 'Employee',
            minWidth: 100,
            field: 'employee',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().employee ? cell.getData().employee : (cell.getData().r_employee ? cell.getData().r_employee: '')
                }</div>
              </div>`
            }
          },
          {
            title: 'Party',
            minWidth: 100,
            field: 'party',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().party ? cell.getData().party : ''
                }</div>
              </div>`
            }
          },
          {
            title: 'Scanned',
            field: 'stock',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `
                ${ cell.getData().stock == 0 ? '<div class="text-center p-1 rounded-md bg-primary-6"> <i data-feather="info" class="w-4 h-4 text-white"></i> </div>' : '<div class="text-center p-1 rounded-md bg-primary-4"> <i data-feather="check" class="w-4 h-4 text-white"></i> </div>' }`
            }
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    const initTabulatorExternal = () => {
      const dateex = moment(filterExternal.date).format('YYYY-MM-DD')
      tabulator.value = new Tabulator(tableRefExternal.value, {
        ajaxURL: BASE_URL + `/closing/closing-packets?type=${filterExternal.type}&man=${filterExternal.man}&dept=${filterExternal.dept}&date=${dateex}&party=${filterExternal.party}`,
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Packet No',
            minWidth: 100,
            responsive: 0,
            field: 'number',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().number
                }</div>
              </div>`
            }
          },
          {
            title: 'Manager',
            minWidth: 100,
            responsive: 0,
            field: 'manager',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
          },
          {
            title: 'Department',
            minWidth: 150,
            field: 'department',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Process',
            minWidth: 100,
            field: 'process',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().process ? cell.getData().process : 'Hold'
                }</div>
              </div>`
            }
          },
          {
            title: 'Party',
            minWidth: 100,
            field: 'party',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().party ? cell.getData().party : ''
                }</div>
              </div>`
            }
          },
          {
            title: 'Scanned',
            field: 'stock',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `
                ${ cell.getData().stock == 0 ? '<div class="text-center p-1 rounded-md bg-primary-6"> <i data-feather="info" class="w-4 h-4 text-white"></i> </div>' : '<div class="text-center p-1 rounded-md bg-primary-4"> <i data-feather="check" class="w-4 h-4 text-white"></i> </div>' }`
            }
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

  // Redraw table onresize
      const reInitOnResizeWindow = () => {
        window.addEventListener('resize', () => {
          tabulator.value.redraw()
          feather.replace({
            'stroke-width': 1.5
          })
        })
      }

  function getManagersandFilter(id) {
    initTabulator()
    getAllManager(id)
  }
  function getAllManager(id) {
    if(id == 'internal') {
        axios({
          url: `/user/allManager?dept_id=${filter.dept}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.manager = res.data.managers
        })
      } else if(id == 'external') {
        axios({
          url: `/user/allManager?dept_id=${filterExternal.dept}`,
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
          config: { headers: { 'Accept':'application/json' } }
        }).then((res) => {
          pagedata.managerExternal = res.data.managers
        })
      }
    }
    watch(() => filter.date, (newValue) => {
      initTabulator()
    })
    watch(() => filterExternal.date, (newValue) => {
      initTabulatorExternal()
    })
    watch(() => status, (newValue) => {
      if(status.value == true) {
        initTabulator()
        setTimeout(() => {
          status.value = false
        }, 200);
      }
    })
    return {
      pagedata,
      filter,
      filterExternal,
      tableRef,
      tableRefExternal,
      reInitOnResizeWindow,
      getManagerData,
      getManagerData,
      getExternalData,
      getManagersandFilter,
      initTabulator,
      initTabulatorExternal,
    }
  }
})
</script>
