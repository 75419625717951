<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-3">
      <div class="w-full sm:w-auto flexsm:mt-0">
      </div>
    </div>
    <div class="pos intro-y grid">
      <!-- BEGIN: Item List -->
      <div class="intro-y col-span-12">
        <div class="grid grid-cols-12 gap-3 mt-1">
          <div class="col-span-4 sm:col-span-3 md:col-span-2 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="whitespace-nowrap overflow-hidden">Total Issue</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-1">{{ pagedata.total_issue }}</span>
                </div>
              </div>
          </div>
          <div class="col-span-4 sm:col-span-3 md:col-span-2 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="whitespace-nowrap overflow-hidden">Total Receive</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-4">{{ pagedata.total_receive }}</span>
                </div>
              </div>
          </div>
          <div class="col-span-4 sm:col-span-3 md:col-span-2 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="whitespace-nowrap overflow-hidden">Total Missing</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-3">{{ pagedata.total_missing }}</span>
                </div>
              </div>
          </div>
          <div @click="getExternalData('external')" class="col-span-4 sm:col-span-3 md:col-span-2 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="whitespace-nowrap overflow-hidden">External</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-9">{{ pagedata.external }}</span>
                </div>
              </div>
          </div>
          <div @click="getExternalData('discuss')" class="col-span-4 sm:col-span-3 md:col-span-2 box text-center cursor-pointer zoom-in p-1">
              <div>
                <div class="whitespace-nowrap overflow-hidden">Discuss</div>
                <div class="text-md">
                  <span class="text-md mr-2 px-2 rounded-lg text-white bg-primary-6">{{ pagedata.discuss }}</span>
                </div>
              </div>
          </div>
        </div>
      </div>
      <!-- END: Item List -->
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class=" flex items-center mt-2 xl:mt-0">
            <div class="input-group mt-2 sm:mt-0">
              <Litepicker
                  v-model="filter.date"
                  :options="{
                    autoApply: false,
                    showWeekNumbers: true,
                    dropdowns: {
                      minYear: 1990,
                      maxYear: null,
                      months: true,
                      years: false,
                    },
                  }"
                  class="form-control block mx-auto mr-3"
                />
            </div>
            <div>
              <TomSelect
                v-model="filter.party"
                @change="initTabulator"
                :options="{
                  placeholder: 'Select your favorite actors'
                }"
                class="w-44"
              >
                <option value="0">Jobwork Party</option>
                <option v-for="dept in pagedata.parties" :key="dept.id" :value="dept.id">{{ dept.firmname }}</option>
              </TomSelect>
            </div>
            <div class="w-48 relative text-gray-700 dark:text-gray-300 ml-4">
              <select
                  class="form-select"
                  v-model="pagedata.type"
                  @change="initTabulator"
                  aria-label="Default select example"
                >
                  <option value="">Select Type</option>
                  <option value="makeable">Makeable</option>
                  <option value="rough">Rough</option>
                  <option value="bunch">Bunch</option>
                  <!-- <option value="lab">Lab</option> -->
                </select>
            </div>
          </div>
        </form>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
   <!-- BEGIN: Closing Modal -->
  <div
    id="closing-packet-modal"
    class="modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <a data-dismiss="modal" href="javascript:;">
          <XIcon class="w-8 h-8 text-gray-500" />
        </a>
        <div class="modal-body py-5 text-center">
          <!-- BEGIN: HTML Table Data -->
          <div class="intro-y box p-5 mt-5">
            <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
              <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
                <div class=" flex items-center mt-2 xl:mt-0">
                  <div class="input-group mt-2 sm:mt-0">
                    <Litepicker
                        v-model="filterExternal.date"
                        :options="{
                          autoApply: false,
                          showWeekNumbers: true,
                          dropdowns: {
                            minYear: 1990,
                            maxYear: null,
                            months: true,
                            years: false,
                          },
                        }"
                        class="form-control block mx-auto mr-3"
                      />
                  </div>
                    <div>
                      <TomSelect
                        v-model="filterExternal.party"
                        @change="initTabulatorExternal"
                        :options="{
                          placeholder: 'Select your favorite actors'
                        }"
                        class="w-44"
                      >
                        <option value="0">JobWork Party</option>
                        <option v-for="dept in pagedata.parties" :key="dept.id" :value="dept.id">{{ dept.firmname }}</option>
                      </TomSelect>
                    </div>
                </div>
              </form>
            </div>
            <div class="overflow-x-auto scrollbar-hidden">
              <div
                id="tabulator"
                ref="tableRefExternal"
                class="mt-5 table-report table-report--tabulator"
              ></div>
            </div>
          </div>
          <!-- END: HTML Table Data -->
        </div>
      </div>
    </div>
  </div>
  <!-- END: Closing Modal -->
  </div>
</template>
<script>
/* eslint-disable */
import { BASE_URL } from '@/constants/config'
import store from '@/store'
import axios from 'axios'
import feather from 'feather-icons'
import moment from 'moment'
import Tabulator from 'tabulator-tables'
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'

export default defineComponent({
  setup() {
    const pagedata = reactive({
      departments: [],
      parties: [],
      total_issue: '',
      total_receive: '',
      total_missing: '',
      date: '',
      type: '',
      external: '',
      discuss: ''
    })
    onMounted(() => {
      // const focusFirst = () => {
      document.querySelector('[id="topbar_search"]').focus();
      // }
      getPageData(),
      initTabulator()
      setTimeout(() => {
        getDepartments()
        getExternalParties()
      }, 500);
    })
    function getPageData() {
      axios({
          url: '/closing/manager-closing',
          method: 'get',
          baseURL: BASE_URL,
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        }).then((res) => {
          pagedata.total_issue = res.data.total
          pagedata.total_receive = res.data.received
          pagedata.total_missing = res.data.missing
          pagedata.date = res.data.date
          pagedata.external = res.data.external
          pagedata.discuss = res.data.discuss
        })
    }
    function getDepartments() {
      axios({
        url: '/allDepartments',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.departments = res.data.departments
      })
    }
    const status = computed(() => {
      return store.state.status
    })
    const tableRef = ref()
    const tableRefExternal = ref()
    const tabulator = ref()
    const filter = reactive({
      man: store.state.user.data.id,
      dept: '0',
      party: '0',
      type: 'all',
      date: moment().format('YYYY-MM-DD')
    })
    const filterExternal = reactive({
      man: store.state.user.data.id,
      dept: '0',
      party: '0',
      type: '',
      date: moment().format('YYYY-MM-DD')
    })
    function getExternalParties() {
      axios({
        url: '/get-external-parties',
        method: 'get',
        baseURL: BASE_URL,
        config: { headers: { 'Accept':'application/json' } }
      }).then((res) => {
        pagedata.parties = res.data.party
      })
    }
    const initTabulator = () => {
      const date = moment(filter.date).format('YYYY-MM-DD')
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: BASE_URL + `/closing/closing-packets?type=${filter.type}&man=${filter.man}&dept=${filter.dept}&date=${date}&party=${filter.party}&diamond_type=${pagedata.type}`,
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Packet No',
            minWidth: 100,
            responsive: 0,
            field: 'number',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().number
                }</div>
              </div>`
            }
          },
          {
            title: 'Manager',
            minWidth: 100,
            responsive: 0,
            field: 'manager',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
          },
          {
            title: 'Department',
            minWidth: 150,
            field: 'department',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Process',
            minWidth: 100,
            field: 'process',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().process ? cell.getData().process : 'Hold'
                }</div>
              </div>`
            }
          },
          {
            title: 'Employee',
            minWidth: 100,
            field: 'employee',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().employee ? cell.getData().employee : (cell.getData().r_employee ? cell.getData().r_employee: '')
                }</div>
              </div>`
            }
          },
          {
            title: 'Party',
            minWidth: 100,
            field: 'party',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().party ? cell.getData().party : ''
                }</div>
              </div>`
            }
          },
          {
            title: 'Scanned',
            field: 'stock',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `
                ${ cell.getData().stock == 0 ? '<div class="text-center p-1 rounded-md bg-primary-6"> <i data-feather="info" class="w-4 h-4 text-white"></i> </div>' : '<div class="text-center p-1 rounded-md bg-primary-4"> <i data-feather="check" class="w-4 h-4 text-white"></i> </div>' }`
            }
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    const initTabulatorExternal = () => {
      const dateex = moment(filterExternal.date).format('YYYY-MM-DD')
      tabulator.value = new Tabulator(tableRefExternal.value, {
        ajaxURL: BASE_URL + `/closing/closing-packets?type=${filterExternal.type}&man=${filterExternal.man}&dept=${filterExternal.dept}&date=${dateex}&party=${filterExternal.party}`,
        ajaxConfig:{
            method:"GET", //set request type to Position
            headers: {
                "Content-type": 'application/json; charset=utf-8', //set specific content type
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
        },
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },

          // For HTML table
          {
            title: 'Packet No',
            minWidth: 100,
            responsive: 0,
            field: 'number',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().number
                }</div>
              </div>`
            }
          },
          {
            title: 'Manager',
            minWidth: 100,
            responsive: 0,
            field: 'manager',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
          },
          {
            title: 'Department',
            minWidth: 150,
            field: 'department',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false
          },
          {
            title: 'Process',
            minWidth: 100,
            field: 'process',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().process ? cell.getData().process : 'Hold'
                }</div>
              </div>`
            }
          },
          {
            title: 'Employee',
            minWidth: 100,
            field: 'employee',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().employee ? cell.getData().employee : ''
                }</div>
              </div>`
            }
          },
          {
            title: 'Party',
            minWidth: 100,
            field: 'party',
            hozAlign: 'center',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `<div>
                <div class="whitespace-nowrap">${
                  cell.getData().party ? cell.getData().party : ''
                }</div>
              </div>`
            }
          },
          {
            title: 'Scanned',
            field: 'stock',
            vertAlign: 'middle',
            print: false,
            download: false,
            formatter(cell) {
              return `
                ${ cell.getData().stock == 0 ? '<div class="text-center p-1 rounded-md bg-primary-6"> <i data-feather="info" class="w-4 h-4 text-white"></i> </div>' : '<div class="text-center p-1 rounded-md bg-primary-4"> <i data-feather="check" class="w-4 h-4 text-white"></i> </div>' }`
            }
          },
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
  // Redraw table onresize
      const reInitOnResizeWindow = () => {
        window.addEventListener('resize', () => {
          tabulator.value.redraw()
          feather.replace({
            'stroke-width': 1.5
          })
        })
      }
  function getExternalData(data) {
      filterExternal.type = data
      initTabulatorExternal()
      setTimeout(() => {
        cash('#closing-packet-modal').modal('show')
      },100)
    }
    function getTabulatorData() {
      console.warn('jooo')
    }
    watch(() => filter.date, (newValue) => {
      initTabulator()
    })
    watch(() => filterExternal.date, (newValue) => {
      initTabulatorExternal()
    })
    watch(() => status.value,(newValue) => {
      if(status.value == true) {
        initTabulator()
        setTimeout(() => {
          store.state.status = false
        }, 200);
      }
    })
    return {
      pagedata,
      filter,
      tableRef,
      tableRefExternal,
      filterExternal,
      reInitOnResizeWindow,
      getExternalData,
      initTabulator,
      initTabulatorExternal,
      getExternalParties,
      getTabulatorData
    }
  }
})
</script>
