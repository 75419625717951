const state = () => {
  return {
    menu: [

      // Admin Requests
      {
        icon: 'SidebarIcon',
        pageName: 'notifications',
        title: 'Purchase',
        roles: [1,3,8],
        subMenu: [
          {
            icon: 'BoxIcon',
            pageName: 'purchase-view',
            title: 'Makeable Purchase',
            roles: [1,3,8],
          },
          {
            icon: 'BoxIcon',
            pageName: 'rough-purchase-view',
            title: 'Rough Purchase',
            roles: [1, 3,8],
          }
        ]
      },
      // Transactions
      {
        icon: 'AirplayIcon',
        pageName: 'transaction-details',
        title: 'Process',
        roles: [1,2,3,4,8,5,6],
        subMenu: [
          {
            pageName: 'packet-lise-view',
            title: 'Packets',
            roles: [1,2,8,5,6,4]
          },
          {
            pageName: 'mix-packet-lots',
            title: 'Mix Packets',
            roles: [1,2,8]
          },
          {
            pageName: 'single-packet-transaction',
            title: 'Single Packet',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'multiple-issue-transaction',
            title: 'Multiple Issue',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'planner-multiple-issue-transaction',
            title: 'Planning Packets',
            roles: [5,4,6,2,8,1,3]
          },
          {
            icon: 'BoxIcon',
            pageName: 'party-receive-rough-transaction',
            title: 'Multiple Receive',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'mix-packet-receive',
            title: 'Mix Receive',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'planner-multiple-transfer',
            title: 'Plan Done Packets',
            roles: [5,6]
          },
          {
            icon: 'BoxIcon',
            pageName: 'ls-receive-transaction',
            title: 'Recut Receive',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'ls-makeable-transfer',
            title: 'Makeable transfer',
            roles: [1,2,8]
          },
          {
            icon: 'BoxIcon',
            pageName: 'final-gradding-update',
            title: 'Final Gradding',
            roles: [1]
          },
          {
            icon: 'BoxIcon',
            pageName: 'lab-receive',
            title: 'Lab Receive',
            roles: [1,2,8]
          },
        ]
      },
       // Transactions
       {
        icon: 'AirplayIcon',
        pageName: 'transaction-details',
        title: 'Transactions',
        roles: [1,2,8,4,5,6],
        subMenu: [
          {
            pageName: 'rough-purchase-transaction',
            title: 'Bunch Transactions',
            roles: [1,2,8,4,5,6]
          },
          {
            icon: 'BoxIcon',
            pageName: 'transaction-history',
            title: 'Issue Transactions',
            roles: [1,2,8,4,5,6]
          },
          {
            icon: 'BoxIcon',
            pageName: 'receive-transaction-history',
            title: 'ReceiveTransactions',
            roles: [1,2,8,4,5,6]
          },
        ]
      },
      // Final Polish
      {
        icon: 'AirplayIcon',
        pageName: 'final-polish-stock-available',
        title: 'Final Polish',
        roles: [1,2,4,8]
      },
      // Transactions
      {
        icon: 'AirplayIcon',
        pageName: 'transaction-details',
        title: 'Discuss',
        roles: [1,2,4,8],
        subMenu: [
          {
            pageName: 'discuss-stocks',
            title: 'Packets',
            roles: [1,2,4,8]
          },
          {
            pageName: 'discuss-transactions',
            title: 'Transaction',
            roles: [1,2,4,8]
          },
        ]
      },
      // Admin Requests
      {
        icon: 'SidebarIcon',
        pageName: 'notifications',
        title: 'Notification',
        roles: [1,2,8],
        subMenu: [
          {
            icon: 'BoxIcon',
            pageName: 'notification-update-param',
            title: 'Param Change',
            roles: [1,2,8],
          },
        ]
      },
      // Admin Reports
      {
        icon: 'SidebarIcon',
        pageName: 'user_costing_report',
        title: 'Reports',
        roles: [1,2,8],
        external: true,
        page_link: 'process-report',
        // roles: [1,2,8],
      },
      // Outword View
      {
        icon: 'SidebarIcon',
        pageName: 'job-done-list',
        title: 'Job Done',
        roles: [1, 8],
        subMenu: [
          {
            icon: 'MonitorIcon',
            pageName: 'create-jon-done',
            title: 'Job Transfer',
            roles: [1, 8]
          },
          {
            icon: 'MonitorIcon',
            pageName: 'release-stocks',
            title: 'Release Stock',
            roles: [1, 8]
          },
          {
            icon: 'MonitorIcon',
            pageName: 'job-done-list',
            title: 'Transactions',
            roles: [1, 8]
          }
        ]
      },
      {
        icon: 'BoxIcon',
        pageName: 'admin-multiple-packet-plans',
        title: 'Rough Planning',
        roles: [1]
      },
      // Rough To Makeable Transfer
      {
        icon: 'MonitorIcon',
        pageName: 'rough-to-makeable-view',
        title: 'Makeable Transfer',
        roles: [1,2,8]
      },
      {
        icon: 'MonitorIcon',
        pageName: 'makeable-to-rough-view',
        title: 'Rough Transfer',
        roles: [1,2,8]
      },
      // Rough To Makeable Transfer
      // {
      //   icon: 'MonitorIcon',
      //   pageName: 'urgent-packets-view',
      //   title: 'Urgent',
      //   roles: [1]
      // },
      // Closings
      {
        icon: 'MonitorIcon',
        pageName: 'manager-closings-view',
        title: 'Closings',
        roles: [2,8]
      },
      {
        icon: 'MonitorIcon',
        pageName: 'admin-closings-view',
        title: 'Closings',
        roles: [1]
      },
      // Master
      {
        icon: 'MonitorIcon',
        pageName: 'master',
        title: 'master',
        roles: [1]
      }
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
