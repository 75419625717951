<template>
<div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Rough Issue Transactions</h2>
        <!-- <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <router-link
          :to="{ name: 'add-issue-packet' }"
          tag="button"
           class="btn btn-primary shadow-md mr-2">
           New Issue
          </router-link>
      </div> -->
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
        <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
            <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
                <div class="sm:flex items-center sm:mr-4">
                    <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Field</label>
                    <select id="tabulator-html-filter-field" v-model="filter.field" class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto">
                        <option value="transaction_code">Transaction No</option>
                        <option value="lot_no">Lot No</option>
                        <option value="voucher_no">Kapan No</option>
                    </select>
                </div>
                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                    <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Type</label>
                    <select id="tabulator-html-filter-type" v-model="filter.type" class="form-select w-full mt-2 sm:mt-0 sm:w-auto">
                        <option value="like" selected>like</option>
                        <option value="=">=</option>
                        <option value="<">&lt;</option>
                        <option value="<=">&lt;=</option>
                        <option value=">">></option>
                        <option value=">=">>=</option>
                        <option value="!=">!=</option>
                    </select>
                </div>
                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                    <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2">Value</label>
                    <input id="tabulator-html-filter-value" v-model="filter.value" type="text" class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0" placeholder="Search..." />
                </div>
                <!-- <div class="sm:flex items-center sm:mr-4 ml-2">
                  <select id="tabulator-html-filter-field" v-model="screenData.filter_status" @change="initTabulator" class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto">
                    <option value="0">Select Status</option>
                    <option value="1">Active</option>
                    <option value="2">Received</option>
                    <option value="3">Cancelled</option>
                  </select>
                </div> -->
                <div class="mt-2 xl:mt-0">
                    <button id="tabulator-html-filter-go" type="button" class="btn btn-primary w-full sm:w-16" @click="onFilter">
                        Go
                    </button>
                    <button id="tabulator-html-filter-reset" type="button" class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1" @click="onResetFilter">
                        Reset
                    </button>
                </div>
            </form>
            <div class="flex mt-5 sm:mt-0">

            </div>
        </div>
        <div class="overflow-x-auto scrollbar-hidden">
            <div id="tabulator" ref="tableRef" class="mt-5"></div>
        </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- END: Data List -->
    <div id="rough-process-receive-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <a data-dismiss="modal" href="javascript:;">
                    <XIcon class="w-8 h-8 text-gray-500" />
                </a>
                <div class="modal-body p-10">
                    <!-- <div id="vertical-form"> -->
                    <div class="flex flex-col justify-between mt-5">
                        <!-- BEGIN: Data List -->
                        <div class="intro-y overflow-auto">
                            <div class="flex items-center">
                                <h2 class="intro-y text-lg font-medium mr-5">Process Receive</h2>
                            </div>
                        </div>
                        <div class="grid grid-cols-12 gap-4">
                            <div class="intro-y col-span-12">
                                <!-- BEGIN: Input -->
                                <div class="">
                                    <div id="input" class="py-5">
                                        <div class="preview">
                                            <div>
                                                <!-- <label class="form-label">Kapan No & Stone Type</label> -->
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>
                                                        <div class="">
                                                            <div id="input-group-4">Kapan</div>

                                                            <input id="pos-form-1" v-model="receive_form.kapan" name="kapan" type="text" disabled class="form-control flex-1" placeholder="Kapan No" />
                                                        </div>
                                                    </div>
                                                    <div class="mt-2 sm:mt-0">
                                                        <div id="input-group-3" class="">Stone</div>
                                                        <select class="form-select" v-model="receive_form.stone_id" disabled aria-label="Default select example">
                                                            <option value="1">N</option>
                                                            <option value="2">CVD</option>
                                                            <option value="3">HPHT</option>
                                                        </select>
                                                        <!-- <template v-if="form_validate.stone_id.$error">
                                                            <div v-for="(error, index) in form_validate.stone_id.$errors" :key="index" class="text-theme-6 mt-2">
                                                                {{ error.$message }}
                                                            </div>
                                                        </template> -->
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <!-- <label class="form-label">No. of Pieces & Total Weight</label> -->
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>
                                                        <div>

                                                            <div class="">
                                                                <div id="input-group-3">Issue Pieces</div>
                                                                <input type="text" class="form-control" name="piece" disabled v-model="receive_form.issue_pcs" aria-describedby="input-group-3" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="mt-2 sm:mt-0">
                                                        <div class="">
                                                            <div id="input-group-4">Issue Weight (Ct)</div>
                                                            <input type="text" class="form-control" name="weight" disabled v-model="receive_form.issue_wt" aria-describedby="input-group-4" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <!-- <label class="form-label">No. of Pieces & Total Weight</label> -->
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>
                                                        <div>

                                                            <div class="">
                                                                <div id="input-group-3" :class="{ 'border-theme-6': receive_validate.rc_pcs.$error }">Rec Pieces <span class="text-theme-6"> * </span></div>
                                                                <input type="text" class="form-control" name="piece" :class="{ 'border-theme-6': receive_validate.rc_pcs.$error }" @keypress="$h.preventInvalidInput" autocomplete="off" v-model="receive_form.rc_pcs" @input="calculate_weight_nd_pcs" placeholder="No of Pieces" aria-describedby="input-group-3" />
                                                            </div>
                                                            <template v-if="receive_validate.rc_pcs.$error">
                                                                <div v-for="(error, index) in receive_validate.rc_pcs.$errors" :key="index" class="text-theme-6 mt-2">
                                                                    {{ error.$message }}
                                                                </div>
                                                            </template>

                                                        </div>
                                                    </div>
                                                    <div class="mt-2 sm:mt-0">
                                                        <div class="">
                                                            <div id="input-group-4" :class="{ 'border-theme-6': receive_validate.rc_wt.$error }">Rec Weight (Ct) <span class="text-theme-6"> * </span></div>
                                                            <input type="text" class="form-control" name="weight" :class="{ 'border-theme-6': receive_validate.rc_wt.$error }" @keypress="$h.preventInvalidInput" autocomplete="off" v-model="receive_form.rc_wt" @input="calculate_weight_nd_pcs" placeholder="Weight" aria-describedby="input-group-4" />
                                                        </div>
                                                        <template v-if="receive_validate.rc_wt.$error">
                                                            <div v-for="(error, index) in receive_validate.rc_wt.$errors" :key="index" class="text-theme-6 mt-2">
                                                                {{ error.$message }}
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <!-- <label class="form-label">No. of Pieces & Total Weight</label> -->
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>
                                                        <div>

                                                            <div class="">
                                                                <div id="input-group-3">Rejection Pieces</div>
                                                                <input type="text" class="form-control" name="piece" @keypress="$h.preventInvalidInput" autocomplete="off" v-model="receive_form.f_pcs" @input="calculate_weight_nd_pcs" placeholder="No of Pieces" aria-describedby="input-group-3" />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="mt-2 sm:mt-0">
                                                        <div class="">
                                                            <div id="input-group-4">Rejection Weight (Ct)</div>
                                                            <input type="text" class="form-control" name="weight" @keypress="$h.preventInvalidInput" autocomplete="off" v-model="receive_form.f_wt" @input="calculate_weight_nd_pcs" placeholder="Weight" aria-describedby="input-group-4" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-3">
                                                <!-- <label class="form-label">Stone && Est Value</label> -->
                                                <div class="sm:grid grid-cols-2 gap-2">
                                                    <div>

                                                        <div class="">
                                                            <div id="input-group-4">Ghat Weight (Ct)</div>
                                                            <input type="text" class="form-control" name="weight" @keypress="$h.preventInvalidInput" autocomplete="off" v-model="receive_form.l_wt" disabled placeholder="Weight" aria-describedby="input-group-4" />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="mt-2 sm:mt-0">
                                                            <div class="flex justify-end items-center mt-5">
                                                                <button type="button" :disabled="screenData.r_loading == true" class="btn btn-secondary w-24 m-auto mr-3" data-dismiss="modal" href="javascript:;">Cancel</button>
                                                                <button type="button" @click="process_receive" :disabled="screenData.r_loading == true" class="btn btn-primary w-24 m-auto"> Receive
                                                                    <LogInIcon v-if="screenData.r_loading == false" class="h-4 w-4 ml-2 -mt-1" />
                                                                    <LoadingIcon v-if="screenData.r_loading == true" icon="oval" color="white" class="w-4 h-4 ml-2" /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- END: Input -->
                            </div>
                        </div>
                        <!-- END: Data List -->
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- BEGIN: Single Process Assign Modal -->
</div>
</template>

<script>
import {
    defineComponent,
    computed,
    ref,
    reactive,
    onMounted,
    inject,
    toRefs
} from 'vue'
import xlsx from 'xlsx'
import {
    useStore
} from '@/store'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import VueRouter from 'vue-router'
import {
    useRouter,
    useRoute
} from 'vue-router'
import {
    required,
    minLength,
    maxLength,
    email,
    url,
    integer
} from '@vuelidate/validators'
import {
    BASE_URL
} from '@/constants/config.js'
import {
    helper as $h
} from '@/utils/helper'
import moment from 'moment'
import {
    useVuelidate
} from '@vuelidate/core'

export default defineComponent({
    setup() {
        const router = useRouter()
        const store = useStore()
        const route = useRoute()
        const tableRef = ref()
        const tabulator = ref()
        const filter = reactive({
            field: 'voucher_no',
            type: 'like',
            value: '',
            status: '0',
        })

        const receive_form = reactive({
            trans_id: '',
            rc_pcs: '',
            rc_wt: '',
            kapan: '',
            stone_id: '',
            issue_pcs: '',
            issue_wt: '',
            f_pcs: '',
            f_wt: '',
            l_wt: ''
        })

        const receive_rules = {
            rc_pcs: {
                required
            },
            rc_wt: {
                required,

            },
        }
        const receive_validate = useVuelidate(receive_rules, toRefs(receive_form))

        const swal = inject('$swal')
        const userDetails = computed(() => store.state.user.currentUser)

        const screenData = reactive({
            issuer: '',
            filter_status: '0',
            r_loading: false
        })

        const initTabulator = () => {
            let _this = this;
            tabulator.value = new Tabulator(tableRef.value, {
                ajaxURL: BASE_URL + 'api/kpn/prlp',
                ajaxConfig: {
                    method: "GET", //set request type to Position
                    headers: {
                        "Content-type": 'application/json; charset=utf-8', //set specific content type
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                },
                // ajaxFiltering: true,
                // ajaxSorting: true,
                // printAsHtml: true,
                // printStyled: true,
                // pagination: 'remote',
                // paginationSize: 20,
                // paginationSizeSelector: [20, 30, 40],
                // layout: 'fitColumns',
                // responsiveLayout: false,
                // tooltipsHeader: true,
                // headerSort: false,
                // placeholder: 'No matching records found',
                ajaxParams: {
                    status: (screenData.filter_status != '0' ? screenData.filter_status : '')
                },
                ajaxFiltering: true,
                ajaxSorting: false,
                printAsHtml: true,
                printStyled: true,
                headerSort: false,
                pagination: 'remote',
                paginationSize: 20,
                paginationSizeSelector: [20, 30, 40],
                layout: 'fitColumns',
                responsiveLayout: 'collapse',
                dataTree: true,
                dataTreeStartExpanded: true,
                columns: [

                    // For HTML table
                    {
                        title: 'Transaction No',
                        minWidth: 150,
                        responsive: 0,
                        field: 'transaction_code',
                        vertAlign: 'middle',
                        print: false,
                        download: true,

                        formatter(cell) {
                            // const a = cash(`<div class="w-full m-0">
                            //    <div class="whitespace-nowrap">${
                            //      cell.getData().due
                            //    }</div>
                            //  </div>`)
                            //  cash(a).on('click', function () {
                            //    Packet(cell.getData().id, cell.getData().lot_no, cell.getData().type)
                            //  })
                            //  return a[0]
                            if (cell.getData().status == 1) {
                                const a = cash(`<div><i data-feather="arrow-up-right" class="w-6 h-6 font-bold text-theme-9 mr-1"></i>
                 ${
                  cell.getData().transaction_code
                 }
                </div>`)
                                cash(a).on('click', function () {
                                    // router.push({ 'path' : '/packets/transaction/' + window.btoa(cell.getData().id)})
                                })
                                return a[0]
                            } else if (cell.getData().status == 2) {
                                const a = cash(`<div><i data-feather="arrow-down-left" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>
                 ${
                  cell.getData().transaction_code
                 }
                </div>`)
                                cash(a).on('click', function () {
                                    // router.push({ 'path' : '/packets/transaction/' + window.btoa(cell.getData().id)})
                                })
                                return a[0]
                            } else {
                                const a = cash(`<div><i data-feather="arrow-down-left" class="w-6 h-6 font-bold text-theme-6 mr-1"></i>
                 ${
                  cell.getData().transaction_code
                 }
                </div>`)
                                cash(a).on('click', function () {
                                    // router.push({ 'path' : '/packets/transaction/' + window.btoa(cell.getData().id)})
                                })
                                return a[0]

                            }
                        }
                    },
                    {
                        title: 'Purchase No',
                        minWidth: 120,
                        responsive: 0,
                        field: 'lot_no',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Kapan No',
                        minWidth: 120,
                        responsive: 0,
                        field: 'voucher_no',
                        vertAlign: 'middle',
                        print: false,
                        download: true,
                    },
                    {
                        title: 'Issue PCS <br> Issue WT',
                        field: 'issue_pieces',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        minWidth: 120,
                        print: false,
                        download: true,
                        formatter(cell) {
                            return `<div>
                            <div class="whitespace-nowrap text-center">${
                              cell.getData().issue_pieces ? cell.getData().issue_pieces + ' Pcs' : 'N/A'
                            }<br>${
                              cell.getData().issue_weight ? cell.getData().issue_weight + ' Ct'  : 'N/A'
                            }
                            </div>

                          </div>`

                        }
                    },
                    {
                        title: 'Rec. PCS <br> Rec. WT',
                        field: 'issue_pieces',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        minWidth: 120,
                        print: false,
                        download: true,
                        formatter(cell) {
                            return `<div>
                            <div class="whitespace-nowrap text-center">${
                              cell.getData().receive_pieces ? cell.getData().receive_pieces + ' Pcs' : 'N/A'
                            }<br>${
                              cell.getData().receive_weight ? cell.getData().receive_weight + ' Ct'  : 'N/A'
                            }
                            </div>

                          </div>`

                        }
                    },
                    {
                        title: 'Rej. PCS <br> Rej. WT',
                        field: 'issue_pieces',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        minWidth: 120,
                        print: false,
                        download: true,
                        formatter(cell) {
                            return `<div>
                            <div class="whitespace-nowrap text-center">${
                              cell.getData().fail_pieces ? cell.getData().fail_pieces + ' Pcs' : 'N/A'
                            }<br>${
                              cell.getData().fail_weight ? cell.getData().fail_weight + ' Ct'  : 'N/A'
                            }
                            </div>

                          </div>`

                        }
                    },
                    {
                        title: 'Lose Wt',
                        field: 'lose_weight',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        minWidth: 100,
                        print: false,
                        download: true,
                        formatter(cell) {
                            return `<div>
                            <div class="whitespace-nowrap text-center">${
                              cell.getData().lose_weight ? cell.getData().lose_weight + ' Ct' : 'N/A'
                            }
                            </div>

                          </div>`

                        }
                    },
                    {

                        title: 'Manager/Worker',
                        field: 'karigar_first_name',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        minWidth: 150,
                        print: false,
                        download: true,
                        formatter(cell) {
                            if (cell.getData().issue_by == userDetails.value.id) {

                                if (cell.getData().transaction_type == 1) {
                                    return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
                  <p class="text-theme-9 font-12 font-bold">` + 'Transfer' + `</p>
                </div>`;
                                } else if (cell.getData().transaction_type == 2) {
                                    if (cell.getData().process_type == 1 || cell.getData().process_type == 2 || cell.getData().process_type == 5 || cell.getData().process_type == 6) {

                                        if (cell.getData().employee_role == 7) {
                                            return `<div class="">` + cell.getData().karigar_first_name + ' ' + cell.getData().karigar_last_name + `<br>
                          <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
                      </div>`;
                                        } else if (cell.getData().employee_role == 4) {
                                            return `<div class="">` + cell.getData().pp_first_name + ' ' + cell.getData().pp_last_name + `<br>
                          <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
                      </div>`;
                                        }
                                    } else if (cell.getData().process_type == 3 || cell.getData().process_type == 4) {
                                        return `<div class="">` + cell.getData().planner_first_name + ' ' + cell.getData().planner_last_name + `<br>
                          <p class="text-theme-9 font-12 font-bold">` + cell.getData().process_name + `</p>
                      </div>`;
                                    }
                                } else if (cell.getData().transaction_type == 4) {
                                    return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
                  <p class="text-theme-9 font-12 font-bold">` + 'Job Done' + `</p>
                </div>`;
                                } else if (cell.getData().transaction_type == 3) {
                                    return `<div class="">` + cell.getData().issue_manager_first_name + ' ' + cell.getData().issue_manager_last_name + `<br>
                  <p class="text-theme-9 font-12 font-bold">` + 'Process Done' + `</p>
                </div>`;
                                }

                            } else {
                                if (cell.getData().transaction_type == 1) {
                                    return `<div class="">` + cell.getData().manager_first_name + ' ' + cell.getData().manager_last_name + `<br>
                  <p class="text-theme-9 font-12 font-bold">` + 'Transfer' + `</p>
                </div>`;
                                } else if (cell.getData().transaction_type == 2) {
                                    return `<div class="">` + cell.getData().manager_first_name + ' ' + cell.getData().manager_last_name + `<br>
                  <p class="text-theme-9 font-12 font-bold">` + 'Transfer' + `</p>
                </div>`;
                                } else if (cell.getData().transaction_type == 4) {
                                    return `<div class="">` + cell.getData().manager_first_name + ' ' + cell.getData().manager_first_name + `<br>
                  <p class="text-theme-9 font-12 font-bold">` + 'Job Done' + `</p>
                </div>`;
                                } else if (cell.getData().transaction_type == 3) {
                                    return `<div class="">` + cell.getData().manager_first_name + ' ' + cell.getData().manager_first_name + `<br>
                  <p class="text-theme-9 font-12 font-bold">` + 'Process Done' + `</p>
                </div>`;
                                }
                            }
                        }
                    },
                    {
                        title: 'Issue Date <br> Receive Date',
                        field: 'created_at',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        minWidth: 150,
                        print: false,
                        download: true,
                        formatter(cell) {
                            return cell.getData().created_at + ' <br>' + (cell.getData().receive_date ? cell.getData().receive_date : '-');

                        }
                    },
                    {
                        title: 'Issue IP',
                        field: 'issue_trans_ip',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        minWidth: 150,
                        print: false,
                        download: true,
                        formatter(cell) {
                            return cell.getData().issue_trans_ip;

                        }
                    },
                    {
                        title: 'Status',
                        field: 'status',
                        hozAlign: 'left',
                        vertAlign: 'middle',
                        minWidth: 100,
                        print: false,
                        download: false,
                        formatter(cell) {
                            if (cell.getData().transaction_type == 1) {
                                if (cell.getData().transaction_status == 1) {
                                    return `<div class="flex items-center lg:justify-center text-theme-1">
                    On Hold
                  </div>`
                                } else if (cell.getData().transaction_status == 2) {
                                    return `<div class="flex items-center lg:justify-center text-theme-9">
                  Accepted
                  </div>`
                                } else if (cell.getData().transaction_status == 3) {
                                    return `<div class="flex items-center lg:justify-center text-theme-6">
                    Cancelled
                  </div>`
                                }
                            } else if (cell.getData().transaction_type == 2) {
                                if (cell.getData().process_type == 1 || cell.getData().process_type == 2) {
                                    if (cell.getData().status == 1) {
                                        return `<div class="flex items-center lg:justify-center font-bold text-theme-9">
                    In Process
                  </div>`
                                    } else if (cell.getData().status == 2) {
                                        return `<div class="flex items-center lg:justify-center text-theme-6">
                    Received
                    </div>`
                                    } else if (cell.getData().status == 3) {
                                        return `<div class="flex items-center lg:justify-center text-theme-6">
                      Cancelled
                    </div>`
                                    }
                                } else if (cell.getData().process_type == 3 || cell.getData().process_type == 4 || cell.getData().process_type == 5 || cell.getData().process_type == 6) {
                                    if (cell.getData().transaction_status == 1) {
                                        return `<div class="flex items-center lg:justify-center text-theme-1">
                      On Hold
                    </div>`
                                    } else if (cell.getData().transaction_status == 2) {
                                        if (cell.getData().issue_by == userDetails.value.id) {
                                            return `<div class="flex items-center lg:justify-center text-theme-9">
                      In Process
                      </div>`
                                        } else {
                                            return `<div class="flex items-center lg:justify-center text-theme-9">
                      Accepted
                      </div>`

                                        }
                                    } else if (cell.getData().transaction_status == 3) {
                                        return `<div class="flex items-center lg:justify-center text-theme-6">
                      Cancelled
                    </div>`
                                    }
                                }
                            } else if (cell.getData().transaction_type == 4) {
                                if (cell.getData().transaction_status == 1) {
                                    return `<div class="flex items-center lg:justify-center text-theme-1">
                    On Hold
                  </div>`
                                } else if (cell.getData().transaction_status == 2) {
                                    return `<div class="flex items-center lg:justify-center text-theme-9">
                  Accepted
                  </div>`
                                } else if (cell.getData().transaction_status == 3) {
                                    return `<div class="flex items-center lg:justify-center text-theme-6">
                    Cancelled
                  </div>`
                                }
                            } else if (cell.getData().transaction_type == 3) {
                                if (cell.getData().transaction_status == 1) {
                                    return `<div class="flex items-center lg:justify-center text-theme-1">
                    On Hold
                  </div>`
                                } else if (cell.getData().transaction_status == 2) {
                                    return `<div class="flex items-center lg:justify-center text-theme-9">
                  Accepted
                  </div>`
                                } else if (cell.getData().transaction_status == 3) {
                                    return `<div class="flex items-center lg:justify-center text-theme-6">
                    Cancelled
                  </div>`
                                }
                            }
                        }
                    },
                    {
                        title: ' ',
                        width: 50,
                        field: 'action',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                          const a = cash(`<button class="btn btn-primary btn-sm shadow-md mr-2">
                              <i data-feather="printer" class="w-4 h-4 mr-1"></i></button>`)
                            cash(a).on('click', function () {
                              let url = BASE_URL + 'bunch-jangad' + '?id=' + window.btoa(cell.getData().id)
                              window.open(url, '_blank');
                                // editTransactionStatus(cell.getData().id,cell.getData().issue_by,'withdraw');
                            })
                            return a[0]
                        }
                    },
                    {
                        title: ' ',
                        width: 100,
                        field: 'action',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            if (cell.getData().status == 1 && cell.getData().transaction_status == 2) {
                                const a = cash(`<div class="flex lg:justify-center items-center">
                      <button class="btn btn-primary btn-sm flex items-center mr-3">
                        <i data-feather="check-square" class="w-4 h-4"></i> Receive
                      </button>
                    </div>`)
                                cash(a).on('click', function () {
                                  console.log(cell.getData())
                                    receive_form.issue_pcs = cell.getData().issue_pieces
                                    receive_form.issue_wt = cell.getData().issue_weight
                                    receive_form.kapan = cell.getData().voucher_no
                                    receive_form.stone_id = String(cell.getData().stone_type)
                                    receive_form.trans_id = cell.getData().id
                                    cash('#rough-process-receive-modal').modal('show')
                                    // editTransactionStatus(cell.getData().id,cell.getData().issue_by,'withdraw');
                                })
                                return a[0]
                            } else if(cell.getData().process_id == null && cell.getData().status == 1 && cell.getData().transaction_status == 1 && cell.getData().issue_by == userDetails.value.id) {
                              const a = cash(`<div class="flex lg:justify-center items-center">
                      <button class="btn btn-primary btn-sm flex items-center mr-3">
                        <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Withdraw
                      </button>
                    </div>`)
                    cash(a).on('click', function() {
                      editTransactionStatus(cell.getData().id,cell.getData().issue_by,'withdraw');
                    })
                    return a[0]
                            } else if(cell.getData().process_id == null && cell.getData().status == 1 && cell.getData().transaction_status == 1 && cell.getData().manager_id == userDetails.value.account_id) {
                              const a = cash(`<div class="flex lg:justify-center items-center">
                                  <button class="btn btn-primary btn-sm flex items-center mr-3">
                                    <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Accept
                                  </button>
                                </div>`)
                                cash(a).on('click', function() {
                                  editTransactionStatus(cell.getData().id,cell.getData().issue_by,'accept');
                                })

                                return a[0]
                            }
                        }
                    },
                    {
                        title: '',
                        width: 100,
                        field: 'action',
                        hozAlign: 'center',
                        vertAlign: 'middle',
                        print: false,
                        download: false,
                        formatter(cell) {
                            if (cell.getData().status == 1 && cell.getData().transaction_status == 2) {
                                const a = cash(`<div class="flex lg:justify-center items-center">
                      <button class="btn btn-danger btn-sm flex items-center">
                        <i data-feather="trash-2" class="w-4 h-4 mr-1"></i> Reject
                      </button>
                    </div>`)
                                cash(a).on('click', function () {

                                    cancel_process(cell.getData().id)
                                    // editTransactionStatus(cell.getData().id,cell.getData().issue_by,'withdraw');
                                })
                                return a[0]
                            } else if(cell.getData().process_id == null && cell.getData().status == 1 && cell.getData().transaction_status == 1 && cell.getData().manager_id == userDetails.value.account_id) {
                              const a = cash(`<div class="flex lg:justify-center items-center">
                                  <button class="btn btn-primary btn-sm flex items-center mr-3">
                                    <i data-feather="check-square" class="w-4 h-4 mr-1"></i> Withdraw
                                  </button>
                                </div>`)
                                cash(a).on('click', function() {
                                  editTransactionStatus(cell.getData().id,cell.getData().issue_by,'withdraw');
                                })
                                return a[0]
                            }
                        }
                    },

                ],
                renderComplete() {
                    feather.replace({
                        'stroke-width': 1.5
                    })
                }
            })
        }

        //Edit user
    const editTransactionStatus = (id,user_id,action) => {
      console.log(id);
      console.log(user_id);
      let trans_id = id;

      const params = {
      "trans_id":  trans_id,
      'action':action
      };

      swal({
        title: "Are you sure?",
        text: "You are about to Change transaction status!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirm & Accept",
        showLoaderOnConfirm: true,
        cancelButtonText: "Cancel",

        preConfirm: login => {

          let promise = axios({
            url: "api/kpn/hold_action_kapan_trans",
            method: "post",
            data: params,
            baseURL: BASE_URL,
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),

            },
            config: { headers: { Accept: "application/json" } }
          });
          return promise
            .then(result => result.data)
            .then(data => {
              console.log(data.message);
              if(data.status == "error"){
                swal("Error!", data.message, "error");
              }else{
                swal("Success!", data.message, "success");
                initTabulator()
              }
            })
            .catch(_error => {
              return [];
          });
        },
        allowOutsideClick: () => !swal.isLoading()
      }).then(result => {
        if (result.value) {
          //swal("Success!", "User Password Updated.", "success");
        }
      });
    }

        const calculate_weight_nd_pcs = () => {

          console.log('Done')
          let receive_weight = receive_form.rc_wt ? receive_form.rc_wt : 0
          let fail_weight = receive_form.f_wt ? receive_form.f_wt : 0

          let total_weight = parseFloat(receive_weight) + parseFloat(fail_weight)

          console.log(total_weight)

          if(parseFloat(total_weight) >  parseFloat(receive_form.issue_wt)) {
            receive_form.rc_wt = 0
            swal('Warning','Receive weight can not be greater than issue weight','warning')
          } else {
            receive_form.l_wt = $h.myFloat(parseFloat(receive_form.issue_wt) - parseFloat(total_weight))
          }
        }

        // Redraw table onresize
        const reInitOnResizeWindow = () => {
            window.addEventListener('resize', () => {
                tabulator.value.redraw()
                feather.replace({
                    'stroke-width': 1.5
                })
            })
        }

        // Reject Purchase
        const cancel_process = async (id) => {

            swal({
                title: "Are you sure?",
                text: "You are about to reject process!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Reject",
                showLoaderOnConfirm: true,
                cancelButtonText: "Cancel",

                allowOutsideClick: () => !$Swal.isLoading()
            }).then(result => {
                if (result.isConfirmed) {

                    const params = {
                      trans_id: (id != '' ? window.btoa(id) : ''),
                    }

                    let promise_url = 'api/kpn/prcp'
                    let promise = axios({
                        url: promise_url,
                        method: "POST",
                        data: params,
                        baseURL: BASE_URL,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),

                        },
                        config: {
                            headers: {
                                Accept: "application/json"
                            }
                        }
                    });
                    return promise
                        .then(result => result.data)
                        .then(data => {

                            if (data.status == 'error') {
                                swal('Warning', data.message, 'warning');
                            } else {
                                swal('Success', data.message, 'success');

                                initTabulator()
                            }
                        })
                        .catch(_error => {
                            swal('Error', 'Something went wrong.', 'error');
                        });

                }
            });
        }
        // Filter function
        const onFilter = () => {
            tabulator.value.setFilter(filter.field, filter.type, filter.value, screenData.filter_status)
        }

        //Edit user
        const editUser = (id) => {
            console.log(id);
        }

        // Process Cancel
        const process_receive = async () => {

            receive_validate.value.$touch()
            if (receive_validate.value.$invalid) {
                swal('Error', 'Fields are empty.', 'error');
                return
            }

            const params = {
                trans_id: (receive_form.trans_id != '' ? window.btoa(receive_form.trans_id) : ''),
                rc_pcs: (receive_form.rc_pcs != '' ? window.btoa(receive_form.rc_pcs) : ''),
                rc_wt: (receive_form.rc_wt != '' ? window.btoa(receive_form.rc_wt) : ''),
                f_pcs: (receive_form.f_pcs != '' ? window.btoa(receive_form.f_pcs) : ''),
                f_wt: (receive_form.f_wt != '' ? window.btoa(receive_form.f_wt) : ''),
                l_wt: (receive_form.l_wt != '' ? window.btoa(receive_form.l_wt) : ''),
            }

            let promise_url = 'api/kpn/pmrrp'
            screenData.r_loading = true

            let promise = axios({
                url: promise_url,
                method: "POST",
                data: params,
                baseURL: BASE_URL,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),

                },
                config: {
                    headers: {
                        Accept: "application/json"
                    }
                }
            });
            return promise
                .then(result => result.data)
                .then(data => {

                    if (data.status == 'error') {
                        swal('Warning', data.message, 'warning');
                        screenData.r_loading = false
                    } else {
                        swal('Success', data.message, 'success');

                        receive_form.trans_id = ''
                        receive_form.rc_pcs = ''
                        receive_form.rc_wt = ''
                        receive_form.f_pcs = ''
                        receive_form.f_wt = ''
                        receive_form.l_wt = ''
                        screenData.r_loading = false

                        cash('#rough-process-receive-modal').modal('hide')
                        receive_validate.value.$reset()
                        initTabulator()
                    }
                })
                .catch(_error => {
                    screenData.r_loading = false
                    swal('Error', 'Something went wrong.', 'error');
                });

        }

        // On reset filter
        const onResetFilter = () => {
            filter.field = 'voucher_no'
            filter.type = 'like'
            filter.value = ''
            onFilter()
        }

        // Export
        const onExportCsv = () => {
            tabulator.value.download('csv', 'data.csv')
        }

        const onExportJson = () => {
            tabulator.value.download('json', 'data.json')
        }

        const onExportXlsx = () => {
            const win = window
            win.XLSX = xlsx
            tabulator.value.download('xlsx', 'buyer_list.xlsx', {
                sheetName: 'Buyers'
            })
        }

        const onExportHtml = () => {
            tabulator.value.download('html', 'data.html', {
                style: true
            })
        }

        // Print
        const onPrint = () => {
            tabulator.value.print()
        }

        onMounted(() => {
            initTabulator()
            reInitOnResizeWindow()
        })

        return {
            tableRef,
            screenData,
            filter,
            onFilter,
            onResetFilter,
            onExportCsv,
            onExportJson,
            onExportXlsx,
            onExportHtml,
            onPrint,
            editUser,
            initTabulator,
            receive_form,
            receive_rules,
            receive_validate,
            process_receive,
            calculate_weight_nd_pcs
        }

    }
})
</script>
